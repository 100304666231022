<template>
  <div>
    {{ title + ' ' }}
    <span v-if="type == 'auto'">Авто пуш</span>
    <span v-if="type == 'moment'">Моментальный пуш</span>
    <span v-if="type == 'everyday'">Ежедневный пуш</span>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: `AnalyticsTitle`,
  props: ["type", "title"],
};
</script>
