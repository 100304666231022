import axios from "axios";
export const HTTP = axios.create({
  baseURL:
    window.location.origin === "https://purnis.xyz"
      ? "https://api.purnis.xyz"
      : "https://api-stage.purnis.xyz",
  headers: {
    Authorization: localStorage["token"],
  },
});
