<template>
  <div class="navigation">
    <a href="/">
      <img
        class="navigation__logo"
        src="../../../src/assets/images/logo.svg"
        alt=""
      >
    </a>
    <navigation v-if="log" />
    <button
      v-if="log"
      title="Создать пуш-уведомление"
      class="add-push__btn desktop"
      @click="showAddPush"
    >
      <span>Добавить пуш</span>
      <img
        src="../../assets/images/plus.svg"
        alt=""
      >
    </button>
    <div
      v-if="log"
      class="logout"
      @click="logout"
    >
      <img
        src="../../assets/images/logout.svg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/header/navigation.vue";

export default {
  name: "CustomHeader",
  components: {
    Navigation,
  },
  props: {
    login: {
      type: Boolean,
      default() {
        // eslint-disable-next-line vue/require-valid-default-prop
        return {};
      },
    },
  },
  data() {
    return {
      log: this.login,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      location.reload();
    },
    showAddPush() {
      this.$router.push({ name: "addPush" });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: white;
  text-decoration: none;
}

.navigation {
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;
  position: absolute;
  background: #333333;
  width: calc(100% - 10vw);
  height: 70px;
  z-index: 2;

  ul {
    display: flex;
    &:first-child {
      flex-grow: 1;
    }
    li {
      padding-right: 1em;
    }
  }

  &__logo {
    width: auto;
    height: 50px;
  }

  @media only screen and (max-width: 767px) {
    overflow: hidden;
    padding: 0 15px;
    width: calc(100% - 30px);
  }
}

.brand {
  display: flex;
  align-items: center;
}

.logout {
  &:hover {
    cursor: pointer;
  }
}

.add-push__btn.desktop {
  background: blue;
  border: none;
  width: max-content;
  height: 40px;
  border-radius: 6px;
  color: #fff;
  font-family: HalvarR;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  gap: 10px;

  @media (max-width: 767px) {
    display: none !important;
  }
}
</style>
