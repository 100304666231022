<template>
  <div>
    <div class="wrapper_modal add-push">
      <div
        class="wrapper_modal__window"
        :class="{ long: step === 7 && type !== '1', long_sec: step === 8 }"
      >
        <div class="top__block">
          <h1>{{ titleModal }} ({{ typeDevice }})</h1>
          <div
            class="close__btn"
            @click="showPopUp = !showPopUp"
          >
            X
          </div>
        </div>
        <div
          v-if="step === 2"
          class="modal__second-step"
        >
          <div class="input__item">
            <label class="modal__label">Название*</label>
            <input
              v-model="newPush.Name"
              type="text"
              class="modal__input required"
              :class="{ 'valid-error': $v.newPush.Name.$error }"
              maxlength="50"
              minlength="3"
              @blur="$v.newPush.Name.$touch()"
            >
          </div>
          <div class="input__item">
            <label class="modal__label">Теги*</label>
            <multiselect
              v-model="newPush.tags"
              class="modal__select--single modal__input required"
              :class="{ 'valid-error': $v.newPush.tags.$error }"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите приложение"
              :options="tagsList"
              :allow-empty="true"
              @blur="$v.newPush.tags.$touch()"
            />
          </div>
          <div class="input__item">
            <label class="modal__label">Приложение*</label>
            <multiselect
              v-model="newPush.apps"
              class="modal__select--single modal__input required"
              :class="{ 'valid-error': $v.newPush.apps.$error }"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите приложение"
              :options="apps"
              :allow-empty="true"
              @input="appIncludeType(newPush.apps)"
              @blur="$v.newPush.apps.$touch()"
            />
          </div>
          <div
            v-if="excludeApp"
            class="js-app-input input__item"
          >
            <label class="modal__label">Исключить из рассылки</label>
            <multiselect
              v-model="newPush.appIdExcept"
              class="modal__select--single"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите приложение"
              :options="appExclude"
              :allow-empty="true"
            />
          </div>
          <div class="btns__block">
            <button
              :disabled="checkButton1"
              :class="{ 'opacity-btn': checkButton1 }"
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div v-if="step === 3">
          <p class="modal__intro">
            Необязательныйг шаг. <br> Если вы хотите отправить пуш
            определенным юзерам, то впишите в поле ниже их External ID
          </p>
          <div class="input__item">
            <label class="modal__label">External ID</label>
            <textarea
              v-model="newPush.externalID"
              type="text"
              class="modal__input"
            />
          </div>
          <div
            v-if="Object.keys(extUsers).length > 0"
            style="margin-top: 10px"
          >
            <table class="table">
              <thead>
                <tr>
                  <th>Users</th>
                  <th>Apps</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item1 in extUsers.missing_external_users"
                  :key="item1.id"
                >
                  <td>
                    <span style="color:#ff2a00">{{ item1 }}</span>
                  </td>
                  <td> - </td>
                </tr>
                <tr
                  v-for="item in extUsers.users"
                  :key="item.id"
                >
                  <td>{{ item.external_user_id }}</td>
                  <td>{{ item.id_app[0].name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btns__block">
            <button
              class="back-step__btn"
              @click="back"
            >
              Назад
            </button>
            <button
              v-if="newPush.externalID"
              class="back-step__btn"
              @click="checkID"
            >
              Проверить External ID
            </button>
            <button
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div
          v-if="step === 4"
          class="modal__second-step"
        >
          <p class="modal__intro">
            Если вы ничего не выбираете то пуш будет отправляться всем вне
            зависимости от тегов
          </p>
          <div
            v-for="(item, itemKey) in tags"
            :key="item.id"
            class="input__item"
          >
            <label class="modal__label tags">{{ itemKey }}</label>
            <multiselect
              v-model="choiseTags[itemKey]"
              class="modal__select--single required"
              :multiple="true"
              placeholder="Выберите"
              :options="item"
              :allow-empty="true"
            />
          </div>
          <div class="btns__block">
            <button
              class="back-step__btn"
              @click="back"
            >
              Назад
            </button>
            <button
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div
          v-if="step === 5"
          class="modal__second-step"
        >
          <div class="input__item">
            <label class="modal__label">ГЕО*</label>
            <multiselect
              v-model="newPush.geo"
              class="modal__select--single required"
              :class="{ 'valid-error': $v.newPush.geo.$error }"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите страну"
              :options="geo"
              :allow-empty="true"
              @input="geoIncludeType(newPush.geo)"
              @blur="$v.newPush.geo.$touch()"
            />
          </div>
          <div
            v-if="excludeGeo"
            class="js-geo-input input__item"
          >
            <label class="modal__label">Исключить из рассылки</label>
            <multiselect
              v-model="newPush.geoExcept"
              class="modal__select--single"
              :multiple="true"
              track-by="name"
              label="name"
              placeholder="Выберите страну"
              :options="geoExclude"
              :allow-empty="true"
            />
          </div>
          <div class="btns__block">
            <button
              class="back-step__btn"
              @click="back"
            >
              Назад
            </button>
            <button
              :disabled="checkButton3"
              :class="{ 'opacity-btn': checkButton3 }"
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div
          v-if="step === 6"
          class="modal__second-step"
        >
          <p class="modal__intro">
            Не забудьте настроить постбеки на события "Регистрация/Депозит",
            если вы используете опцию учета свершения/не свершения событий
            "Регистрация/Депозит"
          </p>
          <div class="input__item">
            <label class="modal__label">Совершенное событие*</label>
            <multiselect
              v-model="newPush.statement"
              :class="{ 'valid-error': $v.newPush.statement.$error }"
              class="modal__select--single required"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              placeholder="Выберите событие"
              :options="statement"
              :searchable="false"
              :allow-empty="false"
              @blur="$v.newPush.statement.$touch()"
            />
          </div>
          <div class="input__item">
            <label class="modal__label">Несовершенное событие*</label>
            <multiselect
              v-model="newPush.except"
              :class="{ 'valid-error': $v.newPush.except.$error }"
              class="modal__select--single required"
              deselect-label="Can't remove this value"
              track-by="name"
              label="name"
              placeholder="Выберите событие"
              :options="except"
              :searchable="false"
              :allow-empty="false"
              @blur="$v.newPush.except.$touch()"
            />
          </div>
          <div class="btns__block">
            <button
              class="back-step__btn"
              @click="back"
            >
              Назад
            </button>
            <button
              :disabled="checkButton4"
              :class="{ 'opacity-btn': checkButton4 }"
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div
          v-if="step === 7"
          class="modal__second-step"
        >
          <p class="modal__intro">
            Если вы ничего не выбираете то пуш будет отправляться всем вне
            зависимости от оффера
          </p>
          <div class="input__item">
            <label class="modal__label">Офферы</label>
            <multiselect
              v-model="newPush.Offers"
              class="modal__select--single required"
              :multiple="true"
              placeholder="Выберите оффер"
              :options="offers"
              :allow-empty="true"
            />
          </div>
          <div class="btns__block">
            <button
              class="back-step__btn"
              @click="back"
            >
              Назад
            </button>
            <button
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div
          v-if="step === 8"
          class="modal__second-step"
        >
          <div class="input__item longer">
            <div>
              <label
                v-if="showDate"
                class="modal__label"
              >
                Выбрать время отправки пуша
              </label>
              <label
                v-if="showDelayText"
                class="modal__label"
              >
                Через какое время отправить пуш
              </label>
              <label
                v-if="showMomentText"
                class="modal__label max-width"
              >
                Если пропустить этот пункт, пуш отправиться сразу после
                создания<br><br>
                Выбрать время и дату отправки
              </label>
              <p class="error" />
              <div
                :class="{ 'is-block': showMomentText }"
                class="modal__flex"
              >
                <div
                  v-if="!showDate"
                  class="modal__input--container"
                >
                  <label class="modal__label time">Время</label>
                  <v-date-picker
                    v-if="!showMomentText"
                    v-model="newPush.Delay"
                    :min-date="date"
                    :class="{ 'valid-error': $v.newPush.Delay.$error }"
                    mode="time"
                    is-inline
                    is-dark
                    is24hr
                    color="red"
                    @blur="$v.newPush.Delay.$touch()"
                  />
                  <v-date-picker
                    v-if="showMomentText"
                    v-model="newPush.Delay"
                    :min-date="date"
                    mode="time"
                    is-inline
                    is-dark
                    is24hr
                    color="red"
                    :key="datePickerKey"
                  />
                  <div v-if="showMomentText" class="delete__time" @click="clearTime()">Очистить время</div>
                </div>
                <div class="show-date">
                  <div
                    v-if="showDate"
                    class="modal__input--container"
                  >
                    <p class="modal__label">
                      Постоянная отправка
                    </p>
                    <label class="custom-checkbox">
                      <input
                        v-model="infinitySend"
                        type="checkbox"
                        value="1"
                      >
                      <span />
                    </label>
                  </div>
                  <div
                    v-if="showDate && !infinitySend"
                    class="modal__input--container"
                  >
                    <label class="modal__label">Кол-во дней*</label>
                    <input
                      v-model="newPush.delayDay"
                      :class="{ 'valid-error': $v.newPush.delayDay.$error }"
                      type="text"
                      class="modal__input day"
                      maxlength="2"
                      placeholder="1"
                      @blur="$v.newPush.delayDay.$touch()"
                    >
                  </div>
                </div>
                <div
                  v-if="showMomentText"
                  style="display: flex"
                >
                  <v-date-picker
                    v-model="newDate"
                    :disabled-dates="dates"
                    :min-date="date"
                    :value="date"
                    color="red"
                    is-dark
                  />
                </div>
              </div>
            </div>
            <div
              v-if="showDate"
              class="repeat__day"
              style="width: 35%; min-width: 250px"
            >
              <label class="modal__label">Время отправки</label>
              <div
                v-for="item in itemsDayInput"
                :key="item.id"
                class="checkbox__module"
              >
                <label class="custom-checkbox">
                  <p>
                    {{ item.day }}
                  </p>
                  <input
                    v-model="item.checked"
                    type="checkbox"
                    :value="item.id"
                    @click="addNewTimeInputForEverydayPush"
                  >
                  <span />
                </label>
                <div
                  class="time__inputs--block"
                  :class="{ hide: !item.checked }"
                >
                  <div
                    v-for="time in item.time"
                    :key="time.id"
                    class="time__inputs--item"
                  >
                    <div
                      class="time__input--remove-btn"
                      @click="removeTimeInput(item.time, time.id)"
                    >
                      -
                    </div>
                    <input
                      v-model="time.value"
                      type="time"
                      class="input__time"
                    >
                    <div
                      class="time__input--add-btn"
                      @click="addTimeInput(item.time)"
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!showDelayText"
              class="modal__input--container"
            >
              <p class="modal__label">
                Отправить по локальному времени
              </p>
              <label class="custom-checkbox">
                <input
                  v-model="localTime"
                  type="checkbox"
                  value="1"
                >
                <span />
              </label>
            </div>
          </div>
          <div class="btns__block">
            <button
              class="back-step__btn"
              @click="back"
            >
              Назад
            </button>
            <button
              v-if="!showDate && !showMomentText"
              :disabled="checkButton61"
              :class="{ 'opacity-btn': checkButton61 }"
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
            <button
              v-if="showDate && !infinitySend"
              class="next-step__btn"
              :disabled="checkButton62"
              :class="{ 'opacity-btn': checkButton62 }"
              @click="next"
            >
              Следующий шаг
            </button>
            <button
              v-if="showDate && infinitySend"
              class="next-step__btn"
              :disabled="checkButton621"
              :class="{ 'opacity-btn': checkButton621 }"
              @click="next"
            >
              Следующий шаг
            </button>
            <button
              v-if="showMomentText"
              class="next-step__btn"
              @click="next"
            >
              Следующий шаг
            </button>
          </div>
        </div>
        <div
          v-if="step === 9"
          :class="{ 'not-everyday': !showDate }"
          class="modal__second-step"
        >
          <div
            v-if="type !== '3'"
            style="display: flex;align-items: center;justify-content: space-between;margin: 10px 0;padding-bottom:20px;border-bottom: 2px solid grey"
          >
            <p style="margin: 0;font-family: HalvarR;color: white">
              Загрузить файлом?
            </p>
            <label class="switch">
              <input
                v-model="isFile"
                type="checkbox"
              >
              <span class="slider round" />
            </label>
          </div>
          <form
            v-if="isFile"
            class="upload-file"
            enctype="multipart/form-data"
          >
            <div class="dada">
              <label
                for="myfile2"
                class="chous"
              >
                Выберите файлы
              </label>
              <input
                id="myfile2"
                type="file"
                class="my"
                name="myfile2"
                @change="onFileChange"
              >
            </div>
            <pre style="color: white;max-height: 300px;overflow-y: scroll;">{{ jsonResult }}</pre>
          </form>
          <div
            v-else
            class="input__item--last"
          >
            <b-card no-body>
              <b-tabs card>
                <!-- Render Tabs, supply a unique `key` to each tab -->
                <b-tab
                  v-for="item in tabs"
                  :key="'dyn-tab-' + item.id"
                  :title="`Title:\n${item.data.lang[0].title}`"
                >
                  <div
                    v-if="type === '1'"
                    style="display: flex;align-items: center;justify-content: space-between;padding-bottom:20px;margin: 0 -15px"
                  >
                    <p style="margin: 0;font-family: HalvarR;color: white">
                      Отправить незамедлительно
                    </p>
                    <label class="switch">
                      <input
                        v-model="isSendNow"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                  <div
                    v-if="showDelayText && !isSendNow"
                    class="modal__input--container delay__block"
                  >
                    <label class="modal__label time">Время Задержки</label>
                    <div style="position: relative">
                      <input
                        v-model="item.data.day"
                        type="text"
                        class="modal__input delay required"
                        placeholder="day"
                      >
                    </div>
                    <div style="position: relative">
                      <input
                        v-model="item.data.hour"
                        type="text"
                        class="modal__input delay required"
                        :class="{'valid-error': showErrorHour}"
                        placeholder="hour"
                        @input="checkHour(item.data)"
                      >
                      <span
                        v-if="showErrorHour"
                        class="error-message"
                      >
                        Час не может быть больше 23
                      </span>
                    </div>
                    <div style="position: relative">
                      <input
                        v-model="item.data.minutes"
                        type="text"
                        class="modal__input delay required"
                        :class="{'valid-error': showErrorMinutes}"
                        placeholder="minutes"
                        @input="checkMinutes(item.data)"
                      >
                      <span
                        v-if="showErrorMinutes"
                        class="error-message"
                      >
                        Минуты не могут быть больше 59
                      </span>
                    </div>
                  </div>


                  <div class="push__data">
                    <div class="modal__block">
                      <div class="data__block fixed">
                        <div class="grid__first-el">
                          <label class="modal__label">URL иконки</label>
                          <input
                            v-model="item.data.IcoUrl"
                            type="text"
                            class="modal__input day required"
                            placeholder="https://example.com/fjfk"
                          >
                        </div>
                        <div class="grid__second-el">
                          <label class="modal__label">URL картинки</label>
                          <input
                            v-model="item.data.PicUrl"
                            type="text"
                            class="modal__input day required"
                            placeholder="https://example.com/fjfk"
                          >
                        </div>
                        <div class="grid__last-el">
                          <label class="modal__label">
                            Ссылка при открытии
                          </label>
                          <input
                            v-model="item.data.Url"
                            type="text"
                            class="modal__input day required"
                            placeholder="https://example.com/fjfk"
                          >
                        </div>
                      </div>
                      <div
                        v-for="langs in item.data.lang"
                        :key="langs.id"
                        class="data__block js-add-lang-block"
                        style="position: relative"
                      >
                        <button
                          v-if="langs.value.code !== 'en'"
                          class="delete__lang"
                          @click="deleteLang(langs, item.data.lang)"
                        >
                          <img src="../../assets/images/delete_outline-lang.svg">
                        </button>
                        <div
                          v-if="langs.value.code === 'en'"
                          style="
                            position: absolute;
                            top: 52px;
                            left: 0px;
                            width: 310px;
                            z-index: 1;
                            height: 40px;
                            background: rgba(255, 255, 255, 0.1);
                            border-radius: 8px;
                            cursor: not-allowed;
                          "
                        />
                        <div>
                          <label class="modal__label title">
                            Язык перевода*
                          </label>
                          <multiselect
                            v-model="langs.value"
                            class="modal__select--single"
                            :multiple="false"
                            track-by="name"
                            label="name"
                            placeholder="Выберите Язык"
                            :options="languages"
                            :allow-empty="true"
                          />
                        </div>
                        <div>
                          <label class="modal__label title">Заголовок*</label>
                          <input
                            v-model="langs.title"
                            type="text"
                            class="modal__input day required"
                            placeholder="push title"
                          >
                        </div>
                        <div class="grid__last-el">
                          <label class="modal__label title">Сообщение*</label>
                          <input
                            v-model="langs.text"
                            type="text"
                            class="modal__input text required"
                            style="height: 70px"
                          >
                        </div>
                      </div>
                      <div class="lang-btn__block">
                        <div
                          class="add__lang-btn"
                          @click="addNewLangBlock(item.data.lang)"
                        >
                          Добавить перевод
                          <img src="../../assets/images/add_item.svg">
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-button
                    v-if="showDate"
                    size="sm"
                    variant="danger"
                    class="float-right btn-close__push"
                    @click="closeTab(item.id)"
                  >
                    Close tab
                  </b-button>
                </b-tab>
                <!-- New Tab Button (Using tabs-end slot) -->
                <template #tabs-end>
                  <b-nav-item
                    v-if="showDate"
                    href="#"
                    role="presentation"
                    @click.prevent="newTabClick()"
                  >
                    <b>+</b>
                  </b-nav-item>
                </template>
                <!-- Render this if no tabs -->
              </b-tabs>
            </b-card>
          </div>
        </div>
        <div
          v-if="step === 9"
          class="btns__block"
        >
          <button
            class="back-step__btn"
            @click="back"
          >
            Назад
          </button>
          <button
            :disabled="showErrorHour || showErrorMinutes || disableButton"
            :class="{ 'opacity-btn': showErrorHour || showErrorMinutes || disableButton }"
            class="next-step__btn"
            @click="sendPush"
          >
            Отправить пуш
          </button>
        </div>
      </div>
    </div>
    <error-modal
      v-if="isError"
      :error="error"
      @close="isError = false"
    />
    <div
      v-if="showErrorModal"
      class="modal__main"
    >
      <div class="wrapper_modal__window">
        <div class="top__block">
          <h1>Заполните все обязательные поля "Заголовок", "Текст", "Язык"</h1>
        </div>
        <div class="btns__block">
          <button
            class="next-step__btn"
            @click="showErrorModal = !showErrorModal"
          >
            ОК
          </button>
        </div>
      </div>
    </div>
    <apply-form
      v-if="showPopUp"
      @close="showPopUp = !showPopUp"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
import applyForm from "@/components/elements/applyForm.vue";
import { required, minLength } from "vuelidate/lib/validators";
import { HTTP } from "@/../axios-config";
import Papa from 'papaparse';
import ErrorModal from "@/components/elements/errorModal.vue";
export default {
  name: "stepPushes",
  components: {
    ErrorModal,
    Multiselect,
    applyForm,
  },
  props: ["type", "title", "typeDevice"],
  data() {
    return {
      error: [],
      isError: false,
      isSendNow: false,
      showErrorHour: false,
      showErrorMinutes: false,
      isFile: false,
      csvData: '',
      jsonResult: null,
      dates: {
        weekdays: [],
      },
      extUsers: [],
      isValidID: false,
      excludeApp: false,
      excludeGeo: false,
      showErrorModal: false,
      typePush: this.type,
      titleModal: this.title,
      newPush: [],
      tags: [],
      appExclude: [],
      geo: [],
      geoExclude: [],
      languages: [],
      tabs: [
        {
          id: 1,
          data: {
            IcoUrl: "",
            PicUrl: "",
            Url: "",
            lang: [
              {
                value: {
                    code: "en",
                    id: 1,
                    name: "English",
                  },
                title: "",
                text: "",
                id: 1,
              },
            ],
          },
        },
      ],
      statement: [
        {
          name: "install",
          value: 1,
        },
        {
          name: "Registration",
          value: 2,
        },
        {
          name: "Deposit",
          value: 3,
        },
      ],
      except: [
        {
          name: "None",
          value: 0,
        },
        {
          name: "Registration",
          value: 2,
        },
        {
          name: "Deposit",
          value: 3,
        },
      ],
      apps: [
        {
          id: "all",
          name: "all",
        },
      ],
      itemsDayInput: [
        {
          checked: false,
          day: "Вс",
          id: 0,
          time: [
            {
              value: "",
              id: 1
            },
          ],
        },
        {
          day: "Пн",
          checked: false,
          id: 1,
          time: [
            {
              value: "",
              id: 1
            },
          ],
        },
        {
          day: "Вт",
          checked: false,
          id: 2,
          time: [
            {
              value: "",
              id: 1
            },
          ],
        },
        {
          checked: false,
          day: "Ср",
          id: 3,
          time: [
            {
              value: "",
              id: 1
            },
          ],
        },
        {
          checked: false,
          day: "Чт",
          id: 4,
          time: [
            {
              value: "",
              id: 1
            },
          ],
        },
        {
          checked: false,
          day: "Пт",
          id: 5,
          time: [
            {
              value: "",
              id: 1
            },
          ],
        },
        {
          checked: false,
          day: "Сб",
          id: 6,
          time: [
            {
              value: "",
              id: 1,
            },
          ],
        },
      ],
      offers: ["Всем без офферов"],
      missingExt: [],
      choiseTags: {},
      newDate: "",
      timezone: "",
      showMomentText: false,
      showDelayText: false,
      showDate: false,
      showPopUp: false,
      localTime: false,
      geoIncType: 0,
      tabCounter: 2,
      step: 2,
      date: new Date(),
      infinitySend: 0,
      disableButton: false,
      tagsList: [],
      datePickerKey: 0,
    };
  },
  validations: {
    infinitySend: {required},
    newPush: {
      Name: {
        required,
        minLength: minLength(4),
      },
      tags: { required },
      apps: { required },
      geo: { required },
      statement: { required },
      except: { required },
      Delay: { required },
      delayDay: { required },
    },
    itemsDayInput: {
      checked: { required },
      time: {
        value: { required },
      },
    },
    tabs: {
      data: {
        $each: {
          lang: {
            required,
            title: { required },
            text: { required },
          }
        }
      }
    }
  },
  computed: {
    checkButton1() {
      return this.$v.newPush.Name.$invalid || (this.$v.newPush.apps.$invalid && this.$v.newPush.tags.$invalid);
    },
    checkButton3() {
      return this.$v.newPush.geo.$invalid;
    },
    checkButton4() {
      return (
        this.$v.newPush.statement.$invalid || this.$v.newPush.except.$invalid
      );
    },
    checkButton61() {
      return this.$v.newPush.Delay.$invalid;
    },
    checkButton62() {
      return this.$v.newPush.delayDay.$invalid;
    },
    checkButton621() {
      return !this.infinitySend;
    },
  },
  methods: {
    clearTime() {
      console.log('sss', this.newPush.Delay);
      this.newPush.Delay = null
      console.log('sss2', this.newPush.Delay);
      this.datePickerKey += 1;
    },
    getIds (arr) {
      return arr.map(app => app.id);
    },
    checkHour(item) {
      const hour = parseInt(item.hour, 10);
      this.showErrorHour = (hour > 23) ?? true;
    },
    checkMinutes(item) {
      const minutes = parseInt(item.minutes, 10);
      this.showErrorMinutes = (minutes > 59) ?? true;
    },
    onFileChange(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.csvData = e.target.result;
        this.createJSON(e.target.result);
      };
      reader.readAsText(file);
    },
    convertToMinutes(input) {
      const totalMinutes = (input.match(/(\d+)d/) || [0, 0])[1] * 1440 +
        (input.match(/(\d+)h/) || [0, 0])[1] * 60 +
        (input.match(/(\d+)m/) || [0, 0])[1] * 1;

      return parseInt(totalMinutes);
    },
    createJSON() {
      Papa.parse(this.csvData, {
        header: true,
        delimiter: ",", // Укажите разделитель здесь, если используется другой
        quoteChar: '"', // Укажите символ кавычек, если используется
        complete: (results) => {
          const parsedData = results.data;
          const result = [];
          let hasErrors = false;

          parsedData.forEach((row, index) => {
            const baseObj = {
              delay: row['Delay'] ? this.convertToMinutes(row['Delay'].trim()) : '',
              ico_url: row['Icon URL'] ? row['Icon URL'].trim() : '',
              pic_url: row['Image URL'] ? row['Image URL'].trim() : '',
              url: row['Launch URL'] ? row['Launch URL'].trim() : '',
              name: this.newPush.Name,
            };

            const langObj = {};
            let isValid = true;

            // Обработка первых значений без индекса
            const firstLanguage = row['Language'] ? row['Language'].trim().toLowerCase() : '';
            const firstTitle = row['Title'] ? row['Title'].trim() : '';
            const firstText = row['Text'] ? row['Text'].trim() : '';

            if (firstLanguage) {
              if (!firstTitle || !firstText) {
                isValid = false;
              }
              langObj[firstLanguage] = {
                title: firstTitle,
                text: firstText
              };
            } else {
              isValid = false;
            }

            // Обработка последующих значений с индексом
            Object.keys(row).forEach((key) => {
              const value = row[key].trim();
              const [prefix, index] = key.split('_');

              if (prefix === 'Language' && index) {
                const language = value.toLowerCase();

                if (!langObj[language]) {
                  langObj[language] = { title: '', text: '' };
                }

                const titleKey = `Title_${index}`;
                const textKey = `Text_${index}`;

                if (row[titleKey]) {
                  langObj[language].title = row[titleKey].trim();
                }

                if (row[textKey]) {
                  langObj[language].text = row[textKey].trim();
                }

                // Check if all titles and texts are present
                if (!langObj[language].title || !langObj[language].text) {
                  isValid = false;
                }
              }
            });

            // Проверка на пустой объект lang
            if (Object.keys(langObj).length === 0) {
              isValid = false;
            }

            // Проверка на пустые ключи в lang
            for (const [langKey, values] of Object.entries(langObj)) {
              if (!langKey || !values.title || !values.text) {
                isValid = false;
              }
            }

            if (isValid) {
              baseObj.lang = langObj;
              result.push(baseObj);
            } else {
              hasErrors = true;
            }
          });

          if (hasErrors) {
            this.jsonResult = 'Файл некорректен. Проверьте наличие всех необходимых значений.';
            this.disableButton = true;
            this.csvData = '';
          } else {
            this.jsonResult = JSON.stringify(result, null, 2);
            this.disableButton = false;
          }

          console.log('Converted JSON:', this.jsonResult);
        }
      });
    },
    checkID() {
      const vm = this;
      let externals = [];
      Object.assign(externals, this.newPush.externalID.split(/[,  \n/", /"]/));
      let apps = [];
      for (let i = 0; i < externals.length; i++) {
        if (externals[i].length < 1) {
          externals.splice(i, 1);
        }
      }
      for (let i = 0; i < vm.newPush.apps.length; i++) {
        let appId = vm.newPush.apps[i];
        apps.push(appId.id.toString());
      }
      apps = apps.join();
      console.log('apps', apps);

      if (this.newPush.externalID) {
        HTTP.post("/front/notifytemplatetasks/external",{
            externals_users: externals,
            apps: apps,
          }
        )
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            vm.extUsers = response.data.data.users;
            for (let i = 0; i < vm.extUsers.users.length; i++) {
              let user = vm.extUsers.users[i];

              if (apps.indexOf(user.id_app)) {
                user.id_app = apps;
              }

              console.log('user.id_app', user.id_app.name);
            }
          })
          .catch(function (error) {
            console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }
          });
      }
      console.log('finish');
    },
    deleteLang(item, array) {
      if (item.value.code == 'en') {
        return ;
      }

      for (let i = 0; i < array.length; i++) {
        if (item.id === array[i].id) {
          array.splice(i, 1);
        }
      }
    },
    getApps() {
      const vm = this;

      HTTP.get(`/front/applications/all?type_device=${this.typeDevice}`)
        .then(function (response) {
          let all = {
            name: "All",
            id: "all",
          };
          vm.apps = response.data.data;
          vm.appExclude = response.data.data;
          vm.apps.unshift(all);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getGeo() {
      const vm = this;

      HTTP.get("/front/geo/")
        .then(function (response) {
          let all = {
            name: "All",
            code: "all",
          };
          vm.geoExclude = response.data.data;
          vm.geo = response.data.data;
          vm.geo.unshift(all);
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    getTags() {
      const vm = this;
      HTTP.get(`/front/tags/`)
        .then(function (response) {
          vm.tags = response.data.data;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    getOffers() {
      const vm = this;

      HTTP.get(`/front/offers/10000000/1`)
        .then(function (response) {
          let offersList = response.data.data.offers;
          for (let i = 0; i < offersList.length; i++) {
            vm.offers.push(offersList[i].name);
          }
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    getLanguages() {
      const vm = this;

      HTTP.get("/front/language/")
        .then(function (response) {
          vm.languages = response.data.data;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    closeTab(x) {
      console.log('x',x);
      console.log('this.tabs.length', this.tabs[this.tabs.length - 1].id);
      if (x === this.tabs[this.tabs.length - 1].id) {
        this.tabCounter = this.tabs.length;
      }
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].id === x) {
          this.tabs.splice(i, 1);
        }
      }
    },
    back() {
      if (this.type === '1' && this.step === 9) {
        this.step = 7;
      }
      if (this.typeDevice !== 'android' && this.step === 5) {
        this.step = 2;
      }
      if (this.step > 2) {
        this.step--;
      }
    },
    close() {
      this.$emit('back');
    },
    next() {
      if (this.step < 9) {
        if (this.typeDevice !== 'android' && this.step === 2) {
          this.step = 4;
        }
        this.step++;
        if (this.type === '1' && this.step === 8) {
          this.step = 9;
        }
      }
    },
    appIncludeType(appId) {
      for (let i = 0; i < appId.length; i++) {
        if (
          appId[i].id == "all" ||
          appId[i].name == "Gambling" ||
          appId[i].name == "Betting" ||
          appId[i].name == "Dating"
        ) {
          this.appIncType = 1;
          this.excludeApp = true;
        } else {
          this.excludeApp = false;
        }
      }
    },
    geoIncludeType(geoId) {
      for (let i = 0; i < geoId.length; i++) {
        if (geoId[i].code == "all") {
          this.geoIncType = 1;
          this.excludeGeo = true;
        } else {
          this.excludeGeo = false;
        }
      }
    },
    showCalendar(type) {
      if (type == "2") {
        this.showDate = true;
      } else if (type == "3") {
        this.showMomentText = true;
      } else if (type == "1") {
        this.showDelayText = true;
        this.showDate = true;
      }
    },
    addNewTimeInputForEverydayPush({ target }) {
      // eslint-disable-next-line no-undef
      let parentBlock = $(target).parents(".checkbox__module");
      parentBlock.find(".time__inputs--block").toggleClass("hide");
    },
    removeTimeInput(arr, id) {
      if (arr.length > 1) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id === id) {
            arr.splice(i, 1);
          }
        }
      }
    },
    addTimeInput(arr) {
      if (arr.length < 4) {
        arr.push({
          value: "",
          id: arr[arr.length - 1].id + 1,
        });
      }
    },
    newTabClick() {
      this.tabs.push({
        id: this.tabCounter++,
        data: {
          IcoUrl: "",
          PicUrl: "",
          Url: "",
          lang: [
            {
              value: {
                code: "en",
                id: 1,
                name: "English",
              },
              title: "",
              text: "",
            },
          ],
        },
      });
    },
    addNewLangBlock(data) {
      data.push({
        value: "",
        text: "",
        id: data.length + 1,
      });
    },
    getContentToPush() {
      let pushes = [];
      let contentPush = this.tabs;
      if (this.isFile) {
        return JSON.parse(this.jsonResult);
      }
      for (let cntPush = 0; cntPush < contentPush.length; cntPush++) {
        let itemPush = contentPush[cntPush].data;
        let itemPushLang = itemPush.lang;
        let tempLangArr = {};

        for (let cntLang = 0; cntLang < itemPushLang.length; cntLang++) {
          let lang = itemPushLang[cntLang];

          tempLangArr[lang.value.code] = {
            title: lang.title,
            text: lang.text
          }
        }

        let day = (itemPush.day) ? itemPush.day : 0;

        let hours = (itemPush.hour)
          ? parseInt(day) * 24  + parseInt(itemPush.hour)
          : parseInt(day) * 24;

        let minutes = (itemPush.minutes) ? itemPush.minutes : 0;

        let formatMinutes = (hours > 0)
          ? parseInt(hours * 60  + minutes)
          : parseInt(minutes);

        let delayTime = (!this.isSendNow)
          ? formatMinutes
          : 0;

        pushes.push({
          delay: delayTime,
          name: this.newPush.Name,
          ico_url: itemPush.IcoUrl,
          pic_url: itemPush.PicUrl,
          url: itemPush.Url,
          lang: tempLangArr,
        });
      }

      return pushes;
    },
    getTimeForEverydayPush() {
      let timeInputs = this.itemsDayInput;
      let finalArr = [];

      for (let i = 0; i < timeInputs.length; i++) {
        let timeArr = timeInputs[i].time;
        let tempArr = [];

        for (let j = 0; j < timeArr.length; j++) {
          if (timeArr[j].value) {
            tempArr.push(timeArr[j].value);
          }
        }
        finalArr[i] = tempArr;
        tempArr = [];
      }

      return finalArr;
    },
    getGeosId(arr) {
      return arr.map(geo => geo.code);
    },
    addParamForTemplate(template) {
      if (this.typePush == 2) {
        template.days_send = parseInt(this.newPush.delayDay);
      }

      if (this.typePush == 3) {
        if (!this.newDate) {
          this.newDate = new Date();
        }
        let month = this.newDate.getMonth() + 1;
        let haveZero = month < 10 ? "0" : "";
        let haveZeroDate = this.newDate.getDate() < 10 ? "0" : "";
        let dateMoment =
          this.newDate.getFullYear() +
          "-" +
          haveZero +
          month +
          "-" +
          haveZeroDate +
          this.newDate.getDate();
        template.date = dateMoment;
      }

      if (this.typePush == 3) {
        let delayTime = (this.newPush.Delay) ? this.newPush.Delay : new Date();
        let haveZeroHours = delayTime.getHours() < 10 ? "0" : "";
        let haveZeroMinutes = delayTime.getMinutes() < 10 ? "0" : "";
        let time =
          haveZeroHours +
          delayTime.getHours() +
          ":" +
          haveZeroMinutes +
          delayTime.getMinutes();

        template.delay = time;
      }

      return template;
    },
    sendPush() {
      var vm = this;

      if (!this.isFile) {
        for (let k = 0; k < this.tabs.length; k++) {
          let tabs = this.tabs[k].data;

          for (let i = 0; i < tabs.lang.length; ++i) {
            let lang = tabs.lang[i];

            if (!lang.text || !lang.title || !lang.value.code) {
              this.showErrorModal = true;
              return ;
            }
          }
        }
      }

      let appIncludeType =
        (this.newPush.appIdExcept && this.newPush.appIdExcept.length > 0)
          ? this.getIds(this.newPush.appIdExcept)
          : [];

      let pushContent = this.getContentToPush();
      let timeForEverydayPush = this.getTimeForEverydayPush();

      let geoCodes =
        (this.newPush.geoExcept && this.newPush.geoExcept.length > 0)
          ? this.getGeosId(this.newPush.geoExcept)
          : this.getGeosId(this.newPush.geo);

      let infinitySend = this.infinitySend !== 0 ? 1 : 0;
      let geoIncludeType = this.newPush.geoExcept ? 1 : 0;
      let statusLocalTime = (this.localTime) ? 1 : 0;
      let externals = (this.newPush.externalID) ? this.newPush.externalID.split(',') : [];

      let template = {
        app_inc_type: appIncludeType,
        geo: geoCodes,
        externals_users: externals,
        geo_inc_type: geoIncludeType,
        statement: this.newPush.statement.value,
        except: this.newPush.except.value,
        type: parseInt(this.typePush),
        day_of_the_week: timeForEverydayPush,
        local_time: statusLocalTime,
        type_device: this.typeDevice,
        infinity_send: infinitySend
      };

      if (this.newPush.apps && this.newPush.apps.length > 0) {
        let appIdsSend = this.getIds(this.newPush.apps);
        template.app_id = appIdsSend;
      }
      if (this.newPush.tags && this.newPush.tags.length > 0) {
        template.tags = this.getIds(this.newPush.tags);
      }
      if (this.newPush.Offers  && this.newPush.Offers.length > 0) {
        template.offers = this.newPush.Offers;
      }

      this.addParamForTemplate(template);

      HTTP.post("/front/notifytemplatetasks/",{
          template: template,
          push: pushContent,
          tags: vm.choiseTags,
        }
      )
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          vm.$router.push({ name: "finalStep" });
        })
        .catch(function (error) {
          // vm.$router.push({ name: "errorStep" });
          console.log(error);
            vm.error = error.response.data.data.errors;
            vm.isError = true;
            //
            // if (error.response.status === 401) {
            //   localStorage.removeItem("token");
            //   vm.$router.push({ name: "Login" });
            // }

        });
    },
    fetchTags() {
      HTTP.get(`/front/applications/tags/list/all`)
        .then(response => {
          let data = response.data.data;
          this.tagsList = data;
        })
        .catch((error) => {
          console.log('error', error);
        })
    }
  },
  mounted() {
    this.fetchTags();
    this.getApps();
    this.getTags();
    this.getGeo();
    this.getOffers();
    this.getLanguages();
  },
  created() {
    this.showCalendar(this.typePush);
  },
};
/* eslint-disable */
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="bootstrap/dist/css/bootstrap.css" scoped></style>
<style src="bootstrap-vue/dist/bootstrap-vue.css" scoped></style>

<style lang="scss" scoped>
.table {
  th {
    text-align: center;
  }
}
::-webkit-scrollbar {
  width: 0!important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.repeat__day {
  padding: 0;
  margin-top: -20px;
}
.text {
  font-size: 14px;
  font-family: "HalvarR";
  color: #fafafa;
}
.nav-link {
  color: #ff2a00 !important;
  font-size: 14px;
  font-family: "HalvarR";
}
.red {
  border: 2px solid #ff2a00 !important;
}
@media only screen and (max-width: 767px) {
  .wrapper_modal__window .btns__block {
    padding: 0 25px 40px;
    height: 135px;
  }
}
</style>
<style lang="scss">
.modal__menu {
  padding: 48px 48px 30px 48px;
  background: #242424;
  border-radius: 10px;
  max-height: 520px;
  margin-right: 10px;

  &--item {
    font-family: HalvarR;
    color: #656565;
    border-radius: 24px;
    transition: 0.5s;
    padding: 15px 30px;
    position: relative;
    width: 250px;
    margin: 10px 0;
    cursor: pointer;

    &:hover {
      background: #333333;
    }

    img {
      position: absolute;
      right: 20px;
      top: 13px;
    }

    &.active-tab {
      background: #161616;
      color: #fff;
    }
  }
}

.card-header-tabs {
  display: flex;
  align-items: baseline;

  ::-webkit-scrollbar {
    width: 0px !important;
  }

  .nav-link.active {
    background: #ff2a00 !important;
    border: none !important;
  }

  .nav-link {
    white-space: nowrap;
    color: #f2f2f2 !important;
    font-family: "HalvarR" !important;
    border-radius: 7px !important;
    margin-right: 5px;
    height: 42px;
    padding: 10px 15px;
    text-decoration: none !important;
  }
}

.btn-close__push {
  position: absolute;
  top: -4px;
  right: 10px;
  background: #ff2a00 !important;
  font-family: "HalvarR" !important;
  font-size: 14px;
  height: 40px;
}

.card-header,
.card {
  border: none !important;
}

.modal {
  &__intro {
    margin-top: -5px;
    width: 88%;
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: #e2e2e2;
    opacity: 0.7;

    @media (max-width: 767px) {
      margin: 0 auto;
    }
  }

  &__second-step {
    .multiselect__select {
      right: 0 !important;
      top: 10px !important;
    }

    .input__item {
      @media (max-width: 767px) {
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
          margin-top: 20px;
        }

        label {
          margin: 0 auto 10px;
          width: 90%;
          &.custom-checkbox {
            margin: unset !important;
            width: 48px !important;
          }
        }

        input,
        .multiselect {
          width: 90%;
          margin: 0 auto;
        }

        &--last {
          .multiselect {
            width: 91%;
          }
        }
        &.longer {
          margin-left: 20px;
        }
      }
    }
  }

  &__label {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: rgba(226, 226, 226, 0.7);
    margin-bottom: 10px;
    margin-top: 25px;
    display: flex;

    &.tags {
      text-transform: capitalize;
    }

    &.title {
      flex-direction: inherit;
    }

    &.time {
      margin: 0 !important;
    }

    &.max-width {
      width: 100%;
    }

    .input__length {
      margin-left: 10px;
      color: #ff2a00;
    }
  }

  &__input {
    border: 1px solid rgba(204, 206, 221, 0.2);
    border-radius: 8px;
    font-family: "HalvarR"!important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #e2e2e2;
    width: 100%;

    &--container {
      display: flex;
      align-items: center;
      height: max-content;

      @media only screen and (max-width: 767px) {
        width: 100%;
        justify-content: space-between;
      }

      &:first-child {
        margin-right: 20px;

        @media only screen and (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .modal__label {
        width: auto;
        margin-top: 17px;
        white-space: nowrap;

        @media only screen and (max-width: 767px) {
          width: 150px;
          margin-top: 17px;
          white-space: break-spaces;
        }
      }

      p.modal__label {
        margin-top: 10px;
      }

      .modal__input.day {
        width: 45px;
      }

      .custom-checkbox {
        width: 22px;
        height: 22px;
        margin-bottom: -12px;

        & > span::before {
          content: " ";
          display: inline-block;
          width: 22px;
          height: 22px;
          flex-shrink: 0;
          flex-grow: 0;
          border: 1px solid #adb5bd;
          border-radius: 6px;
          margin-bottom: 10px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
          position: relative;
          top: -3px;
        }
      }
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__second-step {
    .tabs {
      width: 100% !important;
      background: #242424 !important;

      .vmt__nav,
      .vmt__tab-item {
        background: #242424 !important;
      }
      .vmt__nav__item {
        width: 100px;
      }
    }
  }
}

.top__left,
.top__right {
  width: 45%;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .modal__label {
    margin-top: 17px;
    margin-bottom: 0px;
  }
}

.multiselect {
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #e2e2e2;

  &__single {
    background: transparent;
  }

  &__tag {
    background: rgba(204, 206, 221, 0.08);
  }

  &__tag-icon {
    &:after {
      content: "";
      background: url(../../assets/images/delete_item.svg);
      width: 16px;
      height: 16px;
      position: absolute;
      background-size: cover;
      top: 3px;
      right: 3px;
    }
    &:hover {
      background: transparent;
    }
  }

  &__tags {
    border: 1px solid rgba(204, 206, 221, 0.2) !important;
    border-radius: 8px;
    background: transparent !important;
  }

  &__content-wrapper {
    background: #323232 !important;
    border: 1px solid rgba(204, 206, 221, 0.1);
  }

  &__option--highlight {
    background: rgba(204, 206, 221, 0.2);
    outline: none;
    color: #e2e2e2;

    &::after {
      display: none;
    }
  }

  &__option--selected.multiselect__option--highlight {
    background: #ff2a00;
  }
}

.add_var {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #161616;
  width: 30px;
  height: 30px;
  color: #fafafa;
  font-family: "HalvarR";
  opacity: 0.5;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 105px;
  right: 43px;
  z-index: 1;
}

.push__tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //overflow: scroll;
  width: 95%;
  height: 40px;

  .push__tab {
    font-family: "HalvarR";
    color: #fafafa;
    margin-right: 15px;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 10px;

    &.active {
      color: #ff2a00;
      background: #161616;
    }
  }
}

.vc-container.vc-is-dark {
  background-color: transparent;
  border: none;
}

.back-step__btn {
  background: rgba(204, 206, 221, 0.1);
  border-radius: 8px !important;
  padding: 12px 45px;
  font-family: "HalvarR" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 15px !important;
  line-height: 17px !important;
  color: #e2e2e2;
  border: none;

  @media only screen and (min-width: 768px) {
    margin-right: 30px !important;
  }

  @media only screen and (max-width: 767px) {
    margin-right: 0px !important;
  }
}

.close__btn {
  font-family: "HalvarR";
  color: #e2e2e2;
  font-size: 20px;
  margin-left: auto;
  cursor: pointer;
}

.wrapper_modal__window {
  position: relative;
}

.wrapper_modal__window h1 {
  margin-top: 15px;
}

.required {
  border-radius: 8px;
}

.checkbox {
  &__module {
    display: flex;
    width: 90%;
    align-items: center;
    margin-bottom: 10px;
  }

  &__block {
    display: flex;
    flex-direction: column;

    label {
      font-family: "halvarR";
      font-size: 12px;
      color: rgba(226, 226, 226, 0.7);
    }

    input {
      padding: 0;
      margin: 0;
    }
  }
}

.checkbox {
  margin-bottom: 0.2em;
}

.custom-checkbox {
  width: 48px;
  position: relative;
  height: 48px;
  display: flex !important;
  align-items: center;
}

/* для элемента input c type="checkbox" */
.custom-checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox > span {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox > span::before {
  content: " ";
  display: inline-block;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox p {
  font-family: "HalvarR";
  margin: 0 0 10px 0;
  color: #fff;
  position: absolute;
  top: 14px;
  left: 12px;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox > input:not(:disabled):active + span::before {
  background-color: #b3d7ff;
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox > input:focus:not(:checked) + span::before {
  border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox > input:checked + span::before {
  border-color: #ff2a00;
  background-color: #ff2a00;
}

.card-header-tabs {
  margin-top: 7px;
  width: 70%;
  overflow-y: scroll;
  height: 55px;

  @media (max-width: 767px) {
    width: 47%;
    margin-left: 25px;
  }

  li {
    margin-top: 10px;
    &:last-child {
      position: absolute;
      right: 110px;
      top: -4px;
      background: #242424;
    }
  }
}

.tab-content {
  button {
    margin-top: 9px;
  }
}

.vc-is-dark {
  background-color: #242424 !important;
  .vc-time-picker {
    background-color: #242424;

    .vc-date {
      display: none !important;
    }
  }

  .vc-pane-layout {
    font-family: "HalvarR" !important;
    width: 310px !important;
  }

  select {
    background: #242424 !important;
    border: 1px solid rgba(204, 206, 221, 0.2) !important;
    width: 60px !important;
    font-family: "HalvarR";
    margin-top: 0;
    height: 35px;
  }

  .vc-day-content {
    &:focus,
    &:active,
    &:hover {
      background-color: #ff2a00 !important;
    }
  }
}

.time__inputs {
  &--block {
    margin-left: 30px;
    display: flex;
  }

  &--item {
    position: relative;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    .input__time {
      width: 90px;
      font-family: "HalvarR";
      text-align: center;
      background-color: #353537;
      border: none !important;
    }

    .time__input--remove-btn {
      position: absolute;
      left: -10px;
      top: 10px;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-color: #ff2a00;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff2a00;
      background: rgba(#ff2a00, 0.3);
      border: 2px solid #ff2a00;
      font-family: "HalvarR";
    }

    .time__input--add-btn {
      position: absolute;
      right: -10px;
      top: 10px;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-color: #11e000;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #11e000;
      font-family: "HalvarR";
      background: rgba(#11e000, 0.3);
      border: 2px solid #11e000;
    }
  }
}

.repeat__day {
  .custom-checkbox > span::before {
    background: #000;
    border: none !important;
  }
}

.data__block {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: repeat(2, 310px);
  grid-template-rows: repeat(2, 1fr);

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  &.fixed {
    margin-left: -66px;
    padding-left: 55px;
    background: #303030;
    margin-right: -65px;
    padding-bottom: 40px;

    @media (max-width: 767px) {
      margin-left: -16px;
      padding-left: 29px;
      background: #303030;
      margin-right: -16px;
      padding-bottom: 40px;
    }
  }

  &:not(.fixed) {
    .grid__last-el {
      .modal__label {
        margin-top: 10px;
      }
    }
  }

  .grid__last-el {
    grid-column: 1/3;
  }
}

.lang-btn__block {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .add__lang-btn {
    padding: 12px 15px;
    font-family: HalvarR;
    font-size: 15px;
    color: rgba(226, 226, 226, 0.7);
    transition: 0.5s;
    width: max-content;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #000;
    }
  }
}

.not-everyday .card-header {
  display: none !important;
}

.valid-error {
  border: 2px solid #ff2a00;
}

.opacity-btn {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.delay-see {
  padding: 10px 20px;
  margin-left: 20px;
}

.multiselect--disabled {
  background: transparent !important;
  .multiselect__select {
    background: transparent !important;
  }
}

.delete__time {
  font-family: HalvarR;
  color: white;
  font-size: 12px;
  border: 2px solid white;
  border-radius: 10px;
  padding: 5px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.delete__lang {
  position: absolute;
  left: -65px;
  top: 50px;
  border: none;
  width: 40px;
  height: 21px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 0 8px 8px 0 !important;
  background: #ff2a00;
  color: white;

  @media (max-width: 767px) {
    top: -15px;
    left: -15px;
  }
}

.is-block {
  display: block !important;
}

.show-date {
  display: flex;
  align-items: baseline;
  flex-direction: column;

  @media (max-width: 767px) {
    flex-direction: column;
    padding-right: 20px;

    .modal__input--container {
      align-items: center;
      .custom-checkbox {
        justify-content: center;
      }
    }
  }
}

.js-add-lang-block {
  @media (max-width: 767px) {
    margin-top: 25px;
  }
}

textarea.modal__input {
  background: transparent;
  min-height: 150px;
  padding: 8px 15px;
}
</style>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ff2a00;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff2a00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.chous {
  width: 180px;
  height: 50px;
  border-radius: 4px;
  text-align: center;
  margin: 25px;
  cursor: pointer;
  display: block;
  font: 14px/50px Tahoma;
  transition: all 0.18s ease-in-out;
  border: 1px solid #ff2a00;
  background: #ff2a00;
  color: #fff;
  font-family: HalvarR;
}

.chous:hover {
  color: white;
  background-position: bottom left;
}

.my {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.card-header-tabs::-webkit-scrollbar,
pre::-webkit-scrollbar {
  width: 0px !important;
}

.card-header-tabs li:last-child {
  top: -8px;
}

.card-header-tabs li:last-child b {
  font-size: 23px;
}

.dada {
  text-align: center;
}

.delay__block {
  margin: 0!important;
  gap: 30px;
  width: 104%;
  margin-bottom: 30px!important;
}

.tab-pane {
  position: relative;
}
.btn-close__push {
  top: -68px;
}

.error-message {
  color: #ff2a00;
  font-family: HalvarR;
  position: absolute;
  top: 42px;
  font-size: 10px;
  left: 0;

}

.top__block h1 {
  color: white!important;
}
</style>