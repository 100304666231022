<template>
  <div>
    <div class="scroll_container">
      <table class="table">
        <thead>
          <tr>
            <th
              v-for="item in headItems"
              :key="item.key"
            >
              {{ item.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in bodyItems"
            :key="item.key"
            :class="{ error: item.id_onesignal == 'error' }"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.date_send }}
            </td>
            <td v-if="step === 1">
              {{ item.text.name }}
            </td>
            <td>{{ item.text.headings.en }}</td>
            <td>{{ item.text.contents.en }}</td>
            <td
              v-if="step === 1"
              style="width: 100px"
            >
              <div
                class="all__geo"
                @click="watchGeo(item.geo, item.id, item.id_push_send)"
              >
                <img src="../../assets/images/info.svg">
              </div>
            </td>
            <td v-if="step === 2">
              {{ item.geo }}
            </td>
            <td v-if="step === 3">
              {{ item.app.name }}
            </td>
            <td>{{ item.converted_sum }}</td>
            <td>{{ item.ctr }}</td>
            <td style="width: 200px">
              <div class="relative__block">
                <div
                  class="hover__block"
                  style="color: #8181ff"
                >
                  {{ item.successful_sum }}
                  <p
                    class="title__span"
                    style="background: #0000ff"
                  >
                    all
                  </p>
                </div>
                /
                <div
                  class="hover__block"
                  style="color: #11e000"
                >
                  {{ item.converted_sum }}
                  <p
                    class="title__span"
                    style="background: #11e000"
                  >
                    success
                  </p>
                </div>
                /
                <div
                  class="hover__block"
                  style="color: #ff2a00"
                >
                  {{ item.errored_sum }}
                  <p
                    class="title__span"
                    style="background: #ff2a00"
                  >
                    errored
                  </p>
                </div>
                /
                <div
                  class="hover__block"
                  style="color: #ff5900"
                >
                  {{ item.failed_sum }}
                  <p class="title__span">
                    failed
                  </p>
                </div>
              </div>
            </td>
            <td v-if="step === 2">
              <div
                class="next__step"
                @click="goToApps(item.geo)"
              >
                к прилам
                <img src="../../assets/images/double_arrow.svg">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: `TableCustom`,
  props: ["headItems", "bodyItems", "step"],
  data() {
    return {};
  },
  created() {
    console.log('ok', this.bodyItems)
  },
  methods: {
    watchGeo(geo, id, sendId) {
      this.$emit("watchGeo", geo, id, sendId);
    },
    goToApps(itemGeo) {
      this.$emit("goToApps", itemGeo);
    },
  },
};
</script>

<style scoped lang="scss">
.next__step {
  img {
    position: relative;
    top: 3.5px;
  }
}
</style>
