<template>
  <div>
    <main-header :login="true" />
    <div class="main-block custom">
      <div class="top__block">
        <h1>История постбеков</h1>
      </div>
      <Tabs>
        <TabItem name="Настройка оффера">
          <div class="first-tab">
            <div class="wrapper">
              <div class="offer__create">
                <h2>Офферы</h2>
                <div>
                  <label class="modal__label">Имя оффера</label>
                  <input
                    required
                    v-model="offerName"
                    placeholder="Название"
                    type="text"
                    class="modal__input"
                    maxlength="50"
                    :class="{'valid-error': offerName && offerName.length < 3}"
                  >
                  <p v-if="offerName && offerName.length < 3" style="position: absolute;bottom: 38px;font-family: 'HalvarR';color: #ff2a00;font-size: 12px;">Минимальная длина имени 3 символа</p>
                </div>
                <button
                  :disabled="offerName.length < 3"
                  :class="{ 'opacity-btn': offerName.length < 3 }"
                  class="save__btn"
                  @click="sendOffer"
                >
                  Добавить оффер
                </button>
              </div>
            </div>
          </div>
        </TabItem>

        <TabItem name="История">
          <div class="second-tab">
            <div class="table__info">
              <div class="count__list">
                <label class="modal__label">Показывать записей:</label>
                <multiselect
                  v-model="value"
                  :options="listOptions"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  placeholder="10"
                  class="count__multiselect"
                  @input="limitPage(value)"
                />
              </div>
              <div class="table__calendar hide">
                <p>12.08.2022 - 20.08.22</p>
                <button class="postback__info--calendar">
                  <div class="icon calendar" />
                </button>
              </div>
            </div>
            <div class="scroll_container">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Имя оффера</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in offersList.offers"
                    :key="item.id"
                  >
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <ul class="pagination">
                <li v-if="showPreviousButton">
                  <a @click="paginationPage(1)">First</a>
                </li>
                <li v-if="showPreviousButton">
                  <a @click="paginationPage(paginationPage_default - 1)">
                    Prev
                  </a>
                </li>
                <li
                  v-for="page in visiblePages"
                  :key="page"
                  :class="{ active: paginationPage_default === page }"
                >
                  <a @click="paginationPage(page)">{{ page }}</a>
                </li>
                <li v-if="showNextButton">
                  <a @click="paginationPage(paginationPage_default + 1)">
                    Next
                  </a>
                </li>
                <li v-if="showNextButton">
                  <a @click="paginationPage(offersList.pages)">Last</a>
                </li>
              </ul>
              <div>
                <p
                  v-for="item in paginatedData"
                  :key="item"
                >
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
        </TabItem>
      </Tabs>
      <div
        v-if="errorOffer"
        class="modal__main"
      >
        <div class="wrapper_modal__window">
          <div class="top__block">
            <h1>Введите имя оффера</h1>
          </div>
          <div class="btns__block">
            <button
              class="next-step__btn"
              @click="reloadPageError()"
            >
              Отлично
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="addOfferModal"
        style="height: 85%"
        class="modal__main"
      >
        <div class="wrapper_modal__window">
          <div class="top__block">
            <h1>Оффер добавлен</h1>
          </div>
          <div class="btns__block">
            <button
              class="next-step__btn"
              @click="reloadPage()"
            >
              Отлично
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import Multiselect from "vue-multiselect";
import { HTTP } from "@/../axios-config";

export default {
  name: "PostbackPage",
  components: {
    MainHeader,
    Multiselect,
    // Pagination,
  },
  data() {
    return {
      data: [],
      addOfferModal: false,
      errorOffer: false,
      value: "",
      listOption_default: 10,
      maxVisiblePages: 5,
      paginationPage_default: 1,
      listOptions: ["10", "20", "50", "100"],
      offerName: "",
      offerId: "",
      valueDev: "",
      offersList: [],
      postbackHistoryItems: [],
    };
  },
  computed: {
    visiblePages() {
      const startPage = Math.max(
        1,
        this.paginationPage_default - Math.floor(this.maxVisiblePages / 2)
      );
      const endPage = Math.min(
        startPage + this.maxVisiblePages - 1,
        this.offersList.pages
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
    showPreviousButton() {
      return this.paginationPage_default > 1;
    },
    showNextButton() {
      return this.paginationPage_default < this.offersList.pages;
    },
    paginatedData() {
      const startIndex =
        (this.paginationPage_default - 1) * this.listOption_default;
      const endIndex = startIndex + this.listOption_default;
      return this.data.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.getOffers();
  },
  methods: {
    reloadPage() {
      this.getOffers();
      this.offerName = "";
      this.addOfferModal = false;
    },
    reloadPageError() {
      this.errorOffer = false;
    },
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getOffers();
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getOffers();
    },
    getOffers() {
      const vm = this;

      HTTP.get(
        `/front/offers/${this.listOption_default}/${this.paginationPage_default}`
      )
        .then(function (response) {
          vm.offersList = response.data.data;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    sendOffer() {
      var vm = this;
      if (!this.offerName) {
        this.errorOffer = true;
        return;
      }

      HTTP.post("/front/offers/", {
        name: vm.offerName,
      })
        .then(function () {
          vm.addOfferModal = true;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
  }
};
</script>

<style lang="scss" scoped>
.modal__label {
  width: max-content;
}
.first-tab {
  overflow: scroll;
  height: 540px;
}
.second-tab {
  margin-top: 40px;
}
.table {
  th {
    text-transform: uppercase;
  }
}
</style>

<style lang="scss">
.modal__input {
  &.postback {
    padding: 12px 24px;
    color: #e2e2e2;
    max-width: 175px;
  }
}
.first-tab .modal__label {
  width: auto;
}

.save__btn {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding: 11px 45px;
  border: none;
  margin-top: 32px;
  cursor: pointer;
}
.postback--title {
  margin-bottom: 50px;
}
.save__btn:hover {
  background: #c52303;
}

.postback {
  &--title {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #e2e2e2;
    margin-top: 32px;
  }

  &--url {
    color: #7c7c7c;
  }

  &__info {
    display: flex;
    align-items: center;

    &--items {
      display: flex;
      margin-right: 30px;
    }

    &--title {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #e2e2e2;
      opacity: 0.5;
    }

    &--number,
    &--counter {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #e2e2e2;
    }

    &--calendar {
      width: 30px;
      height: 30px;
      background: rgba(204, 206, 221, 0.1);
      border-radius: 6px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-left: auto;
      margin-right: 32px;
      transition: 0.5s;
    }

    .icon.calendar {
      background: url(../assets/images/calendar.svg);
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.5s;
      width: 16px;
      height: 16px;
    }
  }
}

.offer__create {
  h2 {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #e2e2e2;
    margin: 16px 0 38px 0;
  }

  input {
    max-width: 750px;
  }

  button {
    margin-bottom: 0;
  }
}
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
  margin: 20px auto;
  max-width: 500px;

  li {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: rgba(226, 226, 226, 0.4);
    padding: 7px 11px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 7px;
    min-width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background: #0000ff;
      border-radius: 8px;
      color: #e2e2e2;
    }

    &:hover {
      background: #7c7c7c;
    }
  }
}
</style>
