<template>
  <div class="create-tag modal">
    <div class="modal__container">
      <form
        class="create-tag"
        @submit.prevent
      >
        <div class="modal__top">
          <h1>Теги</h1>
          <button
            class="modal__close-btn"
            @click="closeModal"
          >
            ✕
          </button>
        </div>

        <div class="modal__body">
          <div class="modal__input-container">
            <label
              class="modal__label"
            >
              <multiselect
                v-model="selectTags"
                class="modal__select--single multiselect"
                label="name"
                track-by="id"
                placeholder="Теги"
                :options="tagsList"
                :multiple="true"
                :searchable="true"
                :allow-empty="true"
              />
            </label>
          </div>
          <button
            class="modal__create-btn"
            type="button"
            @click="editTag"
          >
            Редактировать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import Multiselect from "vue-multiselect";

export default {
  name: 'EditApp',
  components: { Multiselect },
  props: {
    tagsList: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selectTags: this.item.tags,
    };
  },
  methods: {
    getIds (arr) {
      return arr.map(app => app.id);
    },
    closeModal() {
      this.$emit('close');
    },
    editTag() {
      this.$emit('update', {
        id: this.item.id,
        tags: this.getIds(this.selectTags)
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.create-tag {
  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    background: #161616;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal {
    &__container {
      background: #242424;
      padding: 20px;
      border-radius: 10px;
      min-width: 600px;
      min-height: 200px;
      max-width: 800px;
    }

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0
      }
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px
    }

    &__input-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;


      label {
        gap: 8px;
        width: 100%;

        &:has(.name) {
          width: 100%
        }
      }

      .required {
        margin-top: 0;
      }
    }

    &__close-btn {
      background: transparent;
      color: white;
      border: none;
      font-size: 24px;
      padding: 0;
    }

    &__create-btn {
      width: 200px;
      height: 40px;
      border-radius: 8px;
      background: #ff2a00;
      color: #fff;
      font-size: 16px;
      border: none;
      font-family: HalvarR;
    }
  }
}
</style>

<style lang="scss">
.create-tag {
  .multiselect__select {
    right: 0;
    top: 10px
  }
}
</style>