<template>
  <div>
    <main-header :login="false" />
    <div class="main-block">
      <div class="home">
        <form
          class="login"
          @submit.prevent="onLogin"
        >
          <h1>Личный кабинет</h1>
          <label>Логин</label>
          <input
            v-model="username"
            required
            type="text"
            placeholder="Login"
          >
          <label>Пароль</label>
          <input
            v-model="password"
            required
            type="password"
            placeholder="Password"
          >
          <div class="btn_block">
            <button
              class="log_btn"
              type="submit"
            >
              Войти
            </button>
            <div class="border disable">
              <a class="reg_btn">Зарегистрироваться</a>
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="invalidLogin"
        class="modal__main"
      >
        <div class="wrapper_modal__window">
          <div class="top__block">
            <h1>{{ invalidText }}</h1>
          </div>
          <div class="btns__block">
            <button
              class="next-step__btn"
              @click="invalidLogin = !invalidLogin"
            >
              Ок
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import { HTTP } from "@/../axios-config";
import { cookiesAction } from "../../cookiesAction";

export default {
  name: "LoginView",
  components: {
    MainHeader,
  },
  data() {
    return {
      username: null,
      password: null,
      invalidLogin: false,
      invalidText: "",
    };
  },
  methods: {
    onLogin() {
      const vm = this;
      HTTP.post(`authorize/`, {
        login: vm.username,
        password: vm.password,
      })
        .then(function (response) {
          cookiesAction.setCookie('refresh_token', response.data.data.refresh_token);
          localStorage.setItem("token", response.data.data.access_token);
          localStorage.setItem("access_time", response.data.data.access_token_expiration_datetime);
          if (localStorage["token"]) {
            window.location.href = "/";
          }
        })
        .catch(function (error) {
          console.error('Invalid login or password');
          vm.invalidText = error.message;
          if (error.response.status === 401) {
            vm.invalidText = "неверный логин/пароль";
          }
          vm.invalidLogin = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.disable {
  opacity: 0.5;
  cursor: not-allowed;
}
.main-block {
  width: calc(100vw - 160px);
  height: calc(100vh - 240px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 120px 80px;

  @media only screen and (max-width: 767px) {
    overflow: hidden;
  }
}
.login {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgba(204, 206, 221, 0.1);
  border-radius: 8px;
  color: white;
  padding: 40px 60px;

  h1 {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
    }
  }

  label {
    font-family: "HalvarR";
    font-style: normal;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: rgba(226, 226, 226, 0.7);
  }

  input {
    border: 1px solid rgba(204, 206, 221, 0.2);
    border-radius: 8px;
    color: white;
    background: transparent;
    height: 40px;
    margin: 10px 0 30px;
    width: 405px;
    padding-left: 25px;
    font-family: "HalvarR";

    &:focus {
      background: rgba(204, 206, 221, 0.1);
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .btn_block {
    display: flex;
    justify-content: space-between;

    a,
    button {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #e2e2e2;
      border: none;
      padding: 11px 25px;
      border-radius: 8px;
      max-width: 250px;

      @media only screen and (max-width: 767px) {
        justify-content: center;
      }
    }

    .log_btn {
      background: #ff2a00;
      transition: 0.5s;
      &:hover {
        // transform: scale(0.95);
      }
    }
    .border {
      border-radius: 8px;
      background: linear-gradient(to right, #ff6700, #ff2a00, #830be2, #0000ff);
      padding: 1px;
    }

    .reg_btn {
      background: #29292a;
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      height: 95px;
    }
  }
}
.wrapper_modal__window {
  max-width: 250px;

  @media only screen and (max-width: 767px) {
    max-height: 500px;
  }

  .top__block {
    justify-content: center !important;

    h1 {
      font-size: 16px !important;
    }
  }

  .btns__block {
    margin-top: 0 !important;
  }
}
</style>
