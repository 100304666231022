<template>
  <div>
    <p class="main__title">
      {{ title }}:
    </p>
    <label
      v-for="check in checkboxes"
      :key="check.id"
      class="container"
    >
      {{ check.name }}
      <input
        :value="check.value"
        type="radio"
        name="radio"
        class="checkbox"
      >
      <span class="checkmark" />
    </label>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: `Checkbox`,
  props: ["checkboxTitle", "checkbox"],
  data() {
    return {
      title: this.checkboxTitle,
      checkboxes: this.checkbox,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: #e2e2e2;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: #ff2a00;
      &:after {
        display: block;
      }
    }
  }

  .checkmark:after {
    top: 4px;
    left: 4px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #242424;
  }

  &:hover input ~ .checkmark {
    background-color: #ff2a00;
  }
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #464649;
  border-radius: 50%;
  transition: 0.3s;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
</style>
