<template>
  <div>
    <div :class="className">
      <label class="modal__label">{{ label }}</label>
      <multiselect
        v-model="searchValue"
        :options="optionList"
        :searchable="false"
        :close-on-select="true"
        :show-labels="false"
        placeholder="25"
        class="count__multiselect"
        @input="searchPage(searchValue)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
export default {
  name: `FiltersMultiselect`,
  components: {
    Multiselect,
  },
  props: ["model", "options", "multiple", "placeholder", "label", "className"],
  data() {
    return {
      searchValue: this.model,
      optionList: this.options,
    };
  },
  methods: {
    searchPage(searchValue) {
      this.$emit("searchItem", searchValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
