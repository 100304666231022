<template>
  <div>
    <loader v-if="isLoading" />
    <main-header :login="true" />
    <div
      class="main-block custom"
      :class="{
        overflowHidden: showSeeGeoModal === true,
      }"
    >
      <div class="top__block analytics">
        <h1>
          {{ title }}
          <analytics-title
            :type="$route.params.type"
            :title="''"
          />
        </h1>
        <div class="top__info--block">
          <div class="refresh-data__block">
            <img src="../assets/images/refresh.svg">
            <p
              class="refresh-data__text"
              @click="updateData(typeId)"
            >
              Обновить
            </p>
          </div>
          <div class="update-time__block">
            <p class="update-time__title">
              Последняя синхронизация:
            </p>
            <p class="update-time__text">
              {{ updateTime }}
            </p>
          </div>
        </div>
      </div>
      <div class="type-device">
        <multiselect
          v-model="searchDeviceValue"
          class="modal__select--single"
          :multiple="false"
          track-by="name"
          label="name"
          :options="deviceList"
          :searchable="true"
          :allow-empty="false"
          :show-labels="false"
          @input="devicePage(searchDeviceValue)"
        />
      </div>
      <div class="first-tab">
        <div class="wrapper">
          <div
            id="sort-bar"
            style="margin-top: 0px"
            class="sort-bar anal"
          >
            <filters-multiselect
              :placeholder="'ГЕО'"
              :multiple="true"
              :model="searchGeoValue"
              :options="geoList"
              @searchItem="geoPage"
            />
            <filters-multiselect
              :placeholder="'Приложение'"
              :multiple="true"
              :model="searchAppValue"
              :options="appsList"
              @searchItem="appPage"
            />
            <filters-multiselect
              :placeholder="'Задание'"
              :multiple="true"
              :model="searchNameValue"
              :options="titleList"
              @searchItem="namePage"
            />
            <button
              :class="{ active: isLoadingFile }"
              class="download__csv"
              @click="downloadFile"
            >
              <loader v-if="isLoadingFile" />
              <span v-if="!isLoadingFile">Скачать XLSX</span>
            </button>
          </div>
          <div class="table__info">
            <simple-multiselect
              :label="'показывать записей:'"
              :class-name="'count__list'"
              :placeholder="'25'"
              :multiple="false"
              :model="valueEntries"
              :options="listOptions"
              @searchItem="limitPage"
            />
            <calendar-block
              :min-date="false"
              :max-date="true"
              :date-from="dateFrom"
              :date-to="dateTo"
              :full-calendar="true"
              @searchDateRange="searchDateRange"
            />
          </div>
          <table-custom
            :head-items="headItems"
            :body-items="analyticsItems"
            :step="1"
            @watchGeo="watchGeo"
          />
        </div>
        <ul
          v-if="showPag"
          class="pagination"
        >
          <li
            v-for="pag in pagination.pages"
            :key="pag"
            :class="{ active: paginationPage_default == pag }"
            @click="paginationPage(pag)"
          >
            {{ pag }}
          </li>
        </ul>
      </div>
    </div>
    <see-geo
      v-if="showSeeGeoModal"
      :item="itemGeo"
      :send-push-id="sendPushId"
      :push-id="pushId"
      :type-analytics="$route.params.type"
      :analytics-page="true"
      :date-to="dateToFormater"
      :date-from="dateFromFormater"
      @close="showSeeGeoModal = !showSeeGeoModal"
    />
  </div>
</template>

<script>
import MainHeader from "@/components/header/header.vue";
import seeGeo from "@/components/pushPage/seeGeoAnalytics.vue";
import loader from "@/components/elements/loader.vue";
import AnalyticsTitle from "@/components/elements/analyticsTitle.vue";
import calendarBlock from "@/components/elements/calendarBlock.vue";
import filtersMultiselect from "@/components/elements/filtersMultiselect.vue";
import simpleMultiselect from "@/components/elements/simpleMultiselect.vue";
import tableCustom from "@/components/analytics/tableAnalytics.vue";
import { HTTP } from "@/../axios-config";
import Multiselect from "vue-multiselect";

/* eslint-disable */
export default {
  name: "analyticsPage",
  components: {
    Multiselect,
    AnalyticsTitle,
    MainHeader,
    seeGeo,
    loader,
    calendarBlock,
    filtersMultiselect,
    simpleMultiselect,
    tableCustom,
  },
  data() {
    return {
      headItems: [
        {name: "#",},
        {name: "Время",},
        {name: "Задание",},
        {name: "Заголовок",},
        {name: "Текст",},
        {name: "ГЕО",},
        {name: "Кликов",},
        {name: "CTR",},
        {name: "Отправлено",},
      ],
      title: '',
      typeId: 1,
      isLoadingFile: false,
      showSeeGeoModal: false,
      isLoading: false,
      dateFrom: new Date(new Date() - 172800000),
      dateTo: new Date(),
      newDate: new Date(),
      showCalendar: false,
      showPag: false,
      listOption_default: 25,
      paginationPage_default: 1,
      viewAppPage_default: "all" ,
      viewGeoPage_default: "all",
      viewNamePage_default: "all",
      itemGeo: [],
      pushId: 0,
      valueEntries: "",
      geoList: [],
      appsList: [],
      titleList: [],
      searchGeoValue: "",
      searchAppValue: "",
      searchNameValue: "",
      dateFromFormater: "",
      dateToFormater: "",
      sendPushId: "",
      pagination: [],
      listOptions: ["25", "50", "100"],
      analyticsItems: [],
      updateTime: "",
      deviceList: [],
      searchDeviceValue: null,
      deviceType: null,
    };
  },
  methods: {
    getSettings() {
      const vm = this;
      HTTP.get(`/front/settings/`)
        .then(response => {
          console.log('resp settings', response.data.data);
          let data = response.data.data;
          this.updateTime = data.sync_onesignal_push;
        })
        .catch(err => {
          console.error(err);
        });
    },
    getDeviceType() {
      const vm = this;
      HTTP.get(`/front/type/device/`)
        .then(response => {
          console.log('resp', response.data.data);
          vm.deviceList = response.data.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    devicePage(item) {
      this.deviceType = item;
      localStorage.setItem('deviceType', JSON.stringify(this.deviceType));
      this.getAnalytics()
    },
    loadDevice() {
      let savedOption = JSON.parse(localStorage.getItem('deviceType'));
      let android = {
        id: 2,
        name: 'android',
        ident: 'android',
      };
      this.searchDeviceValue = android;
      this.deviceType = android;
      if (savedOption) {
        this.deviceType = savedOption;
        this.searchDeviceValue = savedOption;
        // this.getAnalytics()
        this.searchDateRange(this.dateFrom, this.dateTo);

      }
    },
    replaceSymbol(geoItems) {
      let temp = "";
      let regexp = /,/gi;
      temp = geoItems.replace(regexp, " ");
      return temp;
    },
    watchGeo(geoItems, pushId, sendPushId) {
      this.itemGeo = this.replaceSymbol(geoItems);
      this.sendPushId = sendPushId;
      window.scrollTo(0,0);
      this.showSeeGeoModal = true;
      this.pushId = pushId;
    },
    paginationPage(page) {
      this.paginationPage_default = page;
      this.getAnalytics();
    },
    limitPage(limit) {
      this.listOption_default = limit;
      this.paginationPage_default = 1;
      this.getAnalytics();
    },
    appPage(appId) {
      if (appId.length < 1) {
        this.viewAppPage_default = "all";
      } else {
        this.viewAppPage_default = appId[0].id;
        for (let i = 1; i < appId.length; i++) {
          this.viewAppPage_default += `,${appId[i].id}`;
        }
      }
      this.getAnalytics();
    },
    geoPage(geoId) {
      if (geoId.length < 1) {
        this.viewGeoPage_default = "all";
      } else {
        this.viewGeoPage_default = geoId[0].code;
        for (let i = 1; i < geoId.length; i++) {
          this.viewGeoPage_default += `,${geoId[i].code}`;
        }
      }
      this.getAnalytics();
    },
    namePage(nameId) {
      if (nameId.length < 1) {
        this.viewNamePage_default = "all";
      } else {
        this.viewNamePage_default = nameId[0].id;
        for (let i = 1; i < nameId.length; i++) {
          this.viewAppPage_default += `,${nameId[i].id}`;
        }
      }
      this.getAnalytics();
    },
    async getAnalyticsType(id) {
      const vm = this;
      this.analyticsItems = [];
      this.pagination = [];

      this.isLoading = true;

      await HTTP
          .get(`/front/analytics/push/${id}?geo=${this.viewGeoPage_default}&apps=${this.viewAppPage_default}&pushesId=${this.viewNamePage_default}&dateFrom=${this.dateFromFormater}&dateTo=${this.dateToFormater}&page=${this.paginationPage_default}&limit=${this.listOption_default}&typeDevice=${this.deviceType.ident}`)
          .then((response) => {
            let data = response.data.data;
            this.allowFilters = data.filter;
            this.analyticsItems = data.content;

            this.pagination = data.pagination;

            this.showPag = this.pagination.pages > 1 ? true : false;

            let appsListTemp = this.allowFilters.apps;

            if (this.titleList.length === 0) {
              this.titleList = this.allowFilters.title;
            }

            if (this.geoList.length === 0) {
              this.geoList = this.allowFilters.geo;
            }

            if (this.appsList.length === 0) {
              for (let i = 0; i < appsListTemp.length; i++) {
                this.appsList.push({
                  id: appsListTemp[i].id,
                  name: appsListTemp[i].name,
                });
              }
            }

            vm.isLoading = false;
          })
          .catch(function (error) {
            console.log(error);
            vm.isLoading = false;
            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }
          });
    },
    updateData(typeId) {
      let vm = this;

      this.isLoading = true;
      HTTP.post(`/front/analytics/update`, {
            status: true,
            typeId: typeId
          }
        )
        .then(function () {
          vm.getSettings();
          vm.getAnalyticsType(typeId);
          vm.isLoading = false;
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    getAnalytics() {
      if (this.$route.params.type === 'auto') {
        this.getAnalyticsType(1);
        this.typeId = 1;
      }
      if (this.$route.params.type === 'everyday') {
        this.getAnalyticsType(2);
        this.typeId = 2;
      }
      if (this.$route.params.type === 'moment') {
        this.getAnalyticsType(3);
        this.typeId = 3;
      }
      return ;
    },
    dateFormatter(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = (month < 10) ? `0${month}` : month;
      day = (day < 10) ? `0${day}` : day;

      return `${year}-${month}-${day}`;
    },
    searchDateRange(dateFrom, dateTo) {
      // let dateFrom = this.dateFrom;
      // let dateTo = this.dateTo;

      this.dateFromFormater = this.dateFormatter(dateFrom);
      this.dateToFormater = this.dateFormatter(dateTo);

      this.showCalendar = false;

      this.getAnalytics();
    },
    downloadFile() {
      const vm = this;
      this.isLoadingFile = true;

      let url = window.location.origin === "https://purnis.xyz"
        ? "https://api.purnis.xyz"
        : "https://api-stage.purnis.xyz";

      HTTP.get(
        `/front/analytics/download/step1/${this.typeId}?geo=${this.viewGeoPage_default}&apps=${this.viewAppPage_default}&pushesId=${this.viewNamePage_default}&dateFrom=${this.dateFromFormater}&dateTo=${this.dateToFormater}&typeDevice=${this.deviceType}`
      )
        .then((response) => {
          let data = response.data.data;
          let link = data.url;
          let linkDownload = document.createElement('a');

          linkDownload.setAttribute('href',url + link);
          linkDownload.setAttribute('download','download');
          onload=linkDownload.click();

          this.isLoadingFile = false;

        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    }
  },
  mounted() {

    this.getDeviceType();

    this.loadDevice();
    this.getSettings();
    // this.getAnalytics();
  },
  watch: {
    $route(to, from) {
      this.getAnalytics();
    }
  },
};

/* eslint-disable */
</script>

<style lang="scss" scoped>
.top__block {
  flex-direction: column;
}
.pagination {
  width: 100vw !important;
}
//.scroll_container {
//  height: 50vh !important;
//}
.wrapper {
  overflow-x: hidden;
  min-height: 500px;
}
#sort-bar.sort {
  justify-content: start;
  div {
    margin-right: 25px;
  }
}

.first-tab {
  height: 540px;
  padding-bottom: 50px;
}
.table {
  overflow-y: scroll;

  th {
    text-transform: uppercase;
  }
  td {
    padding: auto 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 100px;
    white-space: break-spaces;
  }
}
.modal__label {
  width: 175px;
  white-space: nowrap;
}
.postback__info--calendar {
  margin-right: 0;
}
.flex_filter {
  display: flex;
  align-items: baseline;
  width: 100% !important;
  justify-content: inherit;
}
.two_input .multiselect__select:before {
  right: -105px !important;
  top: -12px !important;
}
</style>

<style lang="scss">
.scroll_container {
  //height: 53vh;
  //overflow-y: scroll;
  //overflow-x: hidden;
}

.sort-bar.anal {
  justify-content: left !important;

  div {
    margin-right: 15px;
    @media (max-width: 767px) {
      margin-right: unset;
    }
  }

  .multiselect__tags {
    margin-top: 7px !important;
  }

  .multiselect__select {
    min-width: 80px !important;
    margin-top: -12px;
  }
}

.multiselect__select {
  position: absolute;
  width: 40px;
  height: unset !important;
  right: -20px;
  top: 23px;
  padding: 4px 8px;
  text-align: center;
  display: flex !important;
  transition: transform 0.2s ease;
  align-items: center;
  justify-content: center;
}
.tabs__content {
  overflow: unset !important;
}
.modal__input {
  &.postback {
    padding: 12px 24px;
    color: #e2e2e2;
    max-width: 175px;
  }
}

.save__btn {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding: 11px 45px;
  border: none;
  margin-top: 32px;
}

.postback {
  &--title {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #e2e2e2;
    margin-top: 32px;
  }

  &--url {
    color: #7c7c7c;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    &--items {
      display: flex;
      margin-right: 0px;
      width: 40%;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      &:nth-child(2n) {
        justify-content: end;
      }
    }

    &--title {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #e2e2e2;
      opacity: 0.5;
      white-space: nowrap;
      margin-right: 10px;
    }

    &--number,
    &--counter {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #e2e2e2;
      white-space: nowrap;
    }

    &--calendar {
      width: 30px;
      height: 30px;
      background: rgba(204, 206, 221, 0.1);
      border-radius: 6px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-left: auto;
      margin-right: 32px;
      transition: 0.5s;
    }
  }
}

.offer__create {
  h2 {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #e2e2e2;
    margin: 46px 0 38px 0;
  }

  input {
    max-width: 750px;
  }

  button {
    margin-bottom: 65px;
  }
}
.count__list {
  display: flex;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    margin-right: 11px;
  }
}
.count__multiselect {
  width: 50px !important;
  .multiselect__tags {
    margin-top: 15px !important;
    border: none !important;
  }
  .multiselect__select:before {
    top: 100% !important;
  }
}
.icon.calendar {
  background: url(../assets/images/calendar.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  width: 16px;
  height: 16px;
}
.table {
  &__info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__calendar {
    display: flex;
    align-items: center;
    //width: 210px;
    p {
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #e2e2e2;
    }
  }
}
.csv__btn {
  color: #ff2a00;
  background: transparent;
  border: none;
  font-family: "halvarR";
  height: 42px;
  margin-top: 8px;
}
.new-csv__btn {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding: 0px 24.5px;
  border: none;
  margin-top: 8px;
  white-space: nowrap;
  height: 40px;
}
.icon.double__arrow {
  background: url(../assets/images/double_arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s;
  width: 16px;
  height: 16px;
}
tr.error {
  background: #fe2b003b !important;
}

tr:nth-child(even).error {
  background: #fe2b0024 !important;
}
.item__top--push {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.multiselect__tags input.multiselect__input {
  color: white;
  background: transparent;
  font-size: 14px;
  height: 34px;
  padding: 0;
  margin-top: -7px;
  display: inline-block;
}

.multiselect__tags input.multiselect__input::placeholder {
  color: #ffffff90;
}

.download__csv {
  background: #ff2a00;
  border-radius: 8px;
  font-family: "HalvarR";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: #fff;
  padding: 11px 30px;
  border: none;
  cursor: pointer;
  margin-top: 7px;
  height: 39px;
  margin-left: auto;
  //margin-right: 10px;

  @media (max-width: 767px) {
    width: 100%;
  }

  &.serch__btn {
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: auto;
    display: flex;
    margin-top: 0px;

    @media (max-width: 767px) {
      width: 87%;
      justify-content: center;
    }
  }

  &.active {
    padding: 0;
    max-width: 184px;
    .vld-overlay {
      min-width: 184px !important;
      width: 184px !important;
      height: 39px !important;
      margin-top: -20px !important;
    }

    .vld-icon {
      position: absolute !important;
      left: -60px !important;

      svg {
        width: 32px !important;
      }
    }
  }
}

.table__calendar {
  margin-right: 0px;

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }
}

.table__info {
  position: relative;
  
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: start;

    div:not(.vc-title):not(.vc-header):not(.vc-arrow) {
      width: 100%;
    }
  }
}

.flex__block {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.calendar {
  &__modal {
    position: absolute;
    bottom: -360px !important;
    right: 10px;
    z-index: 1;
    display: block !important;
    gap: 10px;
    background: #242424;
    border-radius: 8px;

    @media (max-width: 767px) {
      width: 315px !important;
    }
  }

  &__item {
    background: #242424;
    border-radius: 10px;
    p {
      font-family: HalvarR;
      margin-bottom: 0;
      margin-left: 20px;
      color: #fff;
    }
  }
}

.refresh-data {
  &__block {
    display: flex;
    margin-top: -16px;
  }

  &__text {
    font-family: 'HalvarR';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    text-decoration-line: underline;
    color: #E2E2E2;
    margin-left: 12px;
    cursor: pointer;
  }
}

.update-time {
  &__block {
    display: flex;

    p {
      font-family: 'HalvarR';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 130%;
      margin-top: 0;
    }
  }

  &__title {
    color: #7c7c7c;
  }

  &__text {
    color: #fff;
    margin-left: 12px;
  }
}
.relative__block {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 3px 0 5px;
  }

  .hover__block {
    position: relative;

    &:hover {
      cursor: pointer;

      .title__span {
        opacity: 1;
      }
    }
  }

  .title__span {
    opacity: 0;
    position: absolute;
    background: #ff2a00;
    color: white;
    font-family: HalvarR;
    font-size: 12px;
    top: 10px;
    left: 0px;
    transition: 0.3s;
    border-radius: 2px;
    padding: 2px 4px;
  }
}

.breadcrumbs {
  &__block {
    display: flex;
    margin-top: -20px;

    @media (max-width: 767px) {
      margin-top: 0;
      flex-direction: column;
    }
  }

  &__first {
    margin-right: 5px;
    font-family: HalvarR;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    margin-top: 15px;
    text-decoration: none;
  }

  &__this {
    font-family: HalvarR;
    font-size: 15px;
    color: #ff2a00;
  }
}

.top__block.analytics {
  flex-direction: column;
}

.next__step {
  cursor: pointer;
}
h1 {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
