<template>
  <div>
    <div class="wrapper_modal add-push">
      <div class="wrapper_modal__window">
        <div class="top__block">
          <h1>Вы уверены?</h1>
        </div>
        <div class="btns__block">
          <button
            class="back-step__btn"
            @click="closePopUp"
          >
            Отмена
          </button>
          <button
            class="next-step__btn"
            @click="backToPush"
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplyForm",
  data() {
    return {};
  },
  methods: {
    backToPush() {
      this.$router.push({ name: "push" });
    },
    closePopUp() {
      this.$emit("close", {
        showPopUp: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_modal__window {
  width: 442px;
  .top__block {
    justify-content: center;

    h1 {
      font-size: 20px;
    }
  }
}
</style>
