<template>
  <div>
    <div class="">
      <multiselect
        v-if="trackByTitle"
        v-model="searchValue"
        class="modal__select--single"
        :multiple="multiple"
        :placeholder="placeholder"
        track-by="title"
        label="title"
        :options="options"
        :searchable="true"
        :allow-empty="true"
        :show-labels="false"
        @input="searchPage(searchValue)"
      />
      <multiselect
        v-else
        v-model="searchValue"
        class="modal__select--single"
        :multiple="multiple"
        :placeholder="placeholder"
        track-by="name"
        label="name"
        :options="options"
        :searchable="true"
        :allow-empty="true"
        :show-labels="false"
        @input="searchPage(searchValue)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
export default {
  name: `FiltersMultiselect`,
  components: {
    Multiselect,
  },
  props: ["model", "options", "multiple", "placeholder", "trackByTitle"],
  data() {
    return {
      searchValue: this.model,
      optionList: this.options,
    };
  },
  methods: {
    searchPage(searchValue) {
      this.$emit("searchItem", searchValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect__input {
  margin-bottom: 0 !important;
}
</style>
