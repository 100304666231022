import { render, staticRenderFns } from "./checkbox.vue?vue&type=template&id=0453ca32&scoped=true"
import script from "./checkbox.vue?vue&type=script&lang=js"
export * from "./checkbox.vue?vue&type=script&lang=js"
import style0 from "./checkbox.vue?vue&type=style&index=0&id=0453ca32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0453ca32",
  null
  
)

export default component.exports