<template>
  <div>
    <div class="wrapper_modal add-push">
      <div
        v-if="!stepPush"
        class="wrapper_modal__window short"
      >
        <div class="top__block">
          <h1>Создание пуша</h1>
          <div
            class="close__btn"
            @click="showPopUp = !showPopUp"
          >
            X
          </div>
        </div>
        <checkbox
          class="check__block"
          :checkbox-title="checkboxTitle"
          :checkbox="checkbox"
        />
        <div class="btns__block">
          <button
            class="back-step__btn"
            @click="showPopUp = !showPopUp"
          >
            <span v-if="counter === 1">Закрыть</span>
            <span v-else>Назад</span>
          </button>
          <button
            class="next-step__btn"
            @click="nextSlide"
          >
            Следующий шаг
          </button>
        </div>
      </div>
      <step-pushes
        v-if="stepPush"
        :type="typePush"
        :type-device="typeDevice"
        :title="textPush"
        @back="stepPush = !stepPush"
      />
      <apply-form
        v-if="showPopUp"
        @close="showPopUp = !showPopUp"
      />
    </div>
  </div>
</template>

<script>
import stepPushes from "@/components/pushPage/stepPushes.vue";
import applyForm from "@/components/elements/applyForm.vue";
import checkbox from "@/components/elements/checkbox.vue";

export default {
  name: "AddPush",
  components: {
    checkbox,
    stepPushes,
    applyForm,
  },
  props: {
    typeDevice: {
      type: String, // Укажите тип, например String
      required: true, // Укажите, требуется ли этот пропс (опционально)
    }
  },
  data() {
    return {
      counter: 2,
      stepPush: false,
      showPopUp: false,
      textPush: "",
      typePush: null,
      checkboxTitle: "Выберите тип девайса",
      checkbox: [
        {
          value: 1,
          name: "Автопуш (по конверсиям)",
        },
        {
          value: 2,
          name: "Ежедневная отправка",
        },
        {
          value: 3,
          name: "Моментальная отправка",
        },
      ],
    };
  },
  methods: {
    nextSlide() {
      let checkboxes = document.getElementsByClassName("checkbox");
      for (let index = 0; index < checkboxes.length; index++) {
        if (checkboxes[index].checked) {
          this.stepPush = true;
          this.typePush = checkboxes[index].value;
          this.textPush = checkboxes[index].parentNode.textContent;
        }
      }
    },
    backToPush() {
      this.$router.push({ name: "push" });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns__block {
  display: flex;
  justify-content: center;
  margin-top: 35px;

  @media only screen and (max-width: 767px) {
    height: 90px;
  }
}
</style>

<style lang="scss">
.wrapper_modal {
  &.add-push {
    background: #161616;
    display: flex;
    justify-content: center;
    padding: 120px 0 0 0;
    @media (max-width: 767px) {
      overflow: hidden;
    }
  }
  &__window {
    background: #242424;
    width: 750px;
    height: max-content;
    padding: 30px 50px 50px;
    border-radius: 10px;

    @media only screen and (max-width: 767px) {
      width: 90%;
      height: max-content;
      padding: 0;
      margin: 0 auto;
      overflow: scroll;
      &.long,
      &.long_sec {
        height: 100% !important;
      }
      input {
        width: 90%;
        margin: 0 auto;
      }
    }

    .btns__block {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      @media only screen and (max-width: 767px) {
        justify-content: space-between;
        flex-direction: column-reverse;
        padding: 0 40px 40px;
        height: 90px;
      }
    }

    .next-step__btn {
      background: #ff2a00;
      border-radius: 8px;
      font-family: "HalvarR";
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      color: #fff;
      padding: 11px 45px;
      border: none;
      cursor: pointer;
      min-width: 200px!important;
      transition: .3s!important;
    }
  }
}

.top__block {
  @media only screen and (max-width: 767px) {
    margin: 24px;
  }

  h1 {
    font-size: 24px !important;
  }

  &--page-counter {
    font-family: "HalvarR";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
    text-align: right;
    color: #e2e2e2;
    opacity: 0.7;
    margin: 10px 0 0 20px !important;

    @media (max-width: 767px) {
      margin-right: 10px !important;
      margin-top: -5px !important;
    }
  }
}

.check__block {
  margin: 25px;
}
</style>
