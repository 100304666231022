<template>
  <div>
    <div class="table__calendar">
      <p>
        {{ datesFrom | formatDateNoTime }}
        <span v-if="fullCalendar">- {{ datesTo | formatDateNoTime }}</span>
      </p>
      <button class="postback__info--calendar">
        <div
          class="icon calendar"
          @click="showCalendars = !showCalendars"
        />
      </button>
    </div>
    <div
      v-if="showCalendars"
      class="calendar__modal"
    >
      <div class="flex__block">
        <div class="calendar__item">
          <p>Дата от</p>
          <v-date-picker
            v-if="maxDate"
            v-model="datesFrom"
            :max-date="new Date()"
            color="red"
            is-dark
          />
          <v-date-picker
            v-if="minDate"
            v-model="datesFrom"
            :min-date="new Date()"
            color="red"
            is-dark
          />
        </div>
        <div class="calendar__item">
          <p>Дата до</p>
          <v-date-picker
            v-if="fullCalendar && maxDate"
            v-model="datesTo"
            :min-date="datesFrom"
            :max-date="new Date()"
            color="red"
            is-dark
          />
          <v-date-picker
            v-if="fullCalendar && minDate"
            v-model="datesTo"
            :min-date="dateFrom"
            color="red"
            is-dark
          />
        </div>
      </div>
      <button
        class="download__csv serch__btn"
        @click="searchDate()"
      >
        Применить
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: `CalendarBlock`,
  props: ["dateTo", "dateFrom", "fullCalendar", "minDate", "maxDate"],
  data() {
    return {
      showCalendars: false,
      datesTo: this.dateTo,
      datesFrom: this.dateFrom,
    };
  },
  methods: {
    searchDate() {
      this.$emit("searchDateRange", this.datesFrom, this.datesTo);
      this.showCalendars = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.postback__info--calendar {
  margin-right: 0;
  margin-left: 15px;
  padding: 7px;
}

.table__calendar {
  margin-right: 0px;

  p {
    white-space: nowrap;
  }
}
</style>
