<template>
  <div>
    <loading class="loader" />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
  name: `Loader`,
  components: {
    Loading,
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  z-index: 11111;
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
}
</style>
