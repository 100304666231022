/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import AppView from "@/pages/AppView";
import PushView from "@/pages/PushView";
import AnalyticsView from "@/pages/AnalyticsView.vue";
import AnalyticsPushGeo from "../components/analytics/AnalyticsGeo.vue";
import AnalyticsPushGeoApp from "../components/analytics/AnalyticsGeoApp.vue";
import PostbackView from "@/pages/PostbackView";
import LoginView from "@/pages/LoginView";
import addPushView from "@/pages/addPushView";
import addPushDeviceView from "@/pages/appPushDeviceView.vue"
import finalStepView from "@/pages/finalPushStep";
import finalEditStepView from "@/pages/finalEditPushStep";
import finalStepApp from "../components/appPage/addAppNotif";
import pageNotFound from "@/pages/PageNotFound";
import GalleryView from "@/pages/GalleryView";
import QueueView from "@/pages/QueueView.vue";
import errorStep from "../components/elements/errorStep";
import TagsView from "@/pages/TagsView.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { title: "Login - PUSH PUP" }
  },
  {
    path: "/",
    name: "push",
    component: PushView,
    meta: { title: "Pushes - PUSH PUP" }
  },
  {
    path: "/apps",
    name: "app",
    component: AppView,
    meta: { title: "Apps - PUSH PUP" }
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
    meta: { title: "Gallery - PUSH PUP" }
  },
  {
    path: '/tags',
    name: 'tags',
    component: TagsView,
    meta: { title: "Tags - PUSH PUP" }
  },
  {
    path: "/analytics/:type",
    name: "analyticsPage",
    component: AnalyticsView,
    meta: { title: "Analytics - PUSH PUP" }
  },
  {
    path: "/analytics/:type/:dateTo/:dateFrom/:id-:sendId/",
    name: "analyticsPageGeo",
    component: AnalyticsPushGeo,
    meta: { title: "Geo analytics - PUSH PUP" }
  },
  {
    path: "/analytics/:type/:dateTo/:dateFrom/:id-:sendId/:geo",
    name: "analyticsPageGeoApp",
    component: AnalyticsPushGeoApp,
    meta: { title: "App analytics - PUSH PUP" }
  },
  {
    path: "/postback",
    name: "postbackPage",
    component: PostbackView,
    meta: { title: "Postback - PUSH PUP" }
  },
  {
    path: "/queue/:type",
    name: "queuePage",
    component: QueueView,
    meta: { title: "Queue - PUSH PUP" }
  },
  {
    path: "/add-push",
    name: "addPush",
    component: addPushDeviceView,
    meta: { title: "Add Push - PUSH PUP" }
  },
  {
    path: "/thank-you",
    name: "finalStep",
    component: finalStepView,
    meta: { title: "Thank you - PUSH PUP" }
  },
  {
    path: "/finish-edit",
    name: "finalEditStep",
    component: finalEditStepView,
    meta: { title: "Finish - PUSH PUP" }
  },
  {
    path: "/thank-addApp",
    name: "finalStepApp",
    component: finalStepApp,
    meta: { title: "Thank you - PUSH PUP" }
  },
  {
    path: "/create-error",
    name: "errorStep",
    component: errorStep,
    meta: { title: "Error - PUSH PUP" }
  },
  {
    path: "*",
    component: pageNotFound,
    meta: { title: "404 - PUSH PUP" }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.name !== 'Login' && !localStorage["token"]) {
    next({ name: 'Login' });
  }
  else {
    next();
  }
})

export default router;
