<script>
/* eslint-disable */
</script>
<template>
  <div>
    <main-header :login="true" />
    <div class="main-block">
      <div class="top__block">
        <h1>Галерея</h1>
        <button class="add-push__btn" @click="addImgModal = !addImgModal">
          <img src="../assets/images/plus.svg" alt="" />
        </button>
      </div>
      <div class="gallery__block">
        <div v-for="img in imgs" :key="img.id">
          <img
            class="gallery__item"
            :src="img.link"
            @click="copyImgUrl(img.link)"
          />
        </div>
      </div>
    </div>
    <div v-if="addImgModal" class="gallery__modal">
      <div class="gallery__modal--window">
        <vue-upload-multiple-image
          dragText="Кликните, что б загрузить"
          browseText="Макс 5 фото"
          markIsPrimaryText=""
        ></vue-upload-multiple-image>
        <div class="modal__close" @click="addImgModal = !addImgModal">Закрыть</div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import MainHeader from "@/components/header/header.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { HTTP } from "@/../axios-config";

export default {
  name: "appPage",
  components: {
    MainHeader,
    VueUploadMultipleImage,
  },
  data() {
    return {
      addImgModal: false,
      imgs: [
        {
          id: 1,
          link: 'https://s1.1zoom.ru/big0/52/Love_Sunrises_and_sunsets_Fingers_Hands_Heart_Sun_532758_1280x897.jpg',
        },
        {
          id: 2,
          link: 'https://s1.1zoom.ru/big0/52/Love_Sunrises_and_sunsets_Fingers_Hands_Heart_Sun_532758_1280x897.jpg',
        },
        {
          id: 3,
          link: 'https://s1.1zoom.ru/big0/52/Love_Sunrises_and_sunsets_Fingers_Hands_Heart_Sun_532758_1280x897.jpg',
        },
        {
          id: 4,
          link: 'https://s1.1zoom.ru/big0/52/Love_Sunrises_and_sunsets_Fingers_Hands_Heart_Sun_532758_1280x897.jpg',
        },
        {
          id: 5,
          link: 'https://s1.1zoom.ru/big0/52/Love_Sunrises_and_sunsets_Fingers_Hands_Heart_Sun_532758_1280x897.jpg',
        },
      ],
      isCopied: false,
      basicAuth: 'Basic ' + btoa('frontend' + ':' + 'KEpf7ueD92JnKwCuEGck9abu'),
    };
  },
  methods: {
    async copyImgUrl(url) {
      try {
        await navigator.clipboard.writeText(url);
        console.log('Copied');
      } catch($e) {
        console.log('Cannot copy');
      }
    },
    fetchActiveApp() {
      const vm = this;
      HTTP.get(`/front/applications/${this.paginationPage_default}/9/0`)
        .then((response) => {
          this.itemsActive = response.data.data;
          this.temp = response.data.data.apps;

          for (let i = 0; i < this.temp.length; i++) {
            this.temp[i].noImage = (!this.temp[i].image || this.temp[i].image == 'ban') ? true : false;

            if (this.temp[i].BanStatus == "0") {
              this.temp[i].statusInGoogle = "Активно";
              this.temp[i].colorStatus = "green";
            } else {
              this.temp[i].statusInGoogle = "В бане";
              this.temp[i].colorStatus = "red";
            }

            this.temp[i].groupId = 'Without group';

            if (this.temp[i].GroupId == 1) {
              this.temp[i].groupId = 'Dating';
            }
            if (this.temp[i].GroupId == 2) {
              this.temp[i].groupId = 'Gambling';
            }
            if (this.temp[i].GroupId == 3) {
              this.temp[i].groupId = 'Betting';
            }
          }
          if (this.itemsActive.pages < 2) {
            this.showActivePagination = false;
          }
          this.items = [];
        })
        .catch(function (error) {
          console.log(error);

            if (error.response.status === 401) {
              localStorage.removeItem("token");
              vm.$router.push({ name: "Login" });
            }

        });
    },
    paginationActivePage(page) {
      this.paginationPage_default = page;
      this.fetchActiveApp();
    },
  },
  async mounted() {
  },
};
</script>

<style lang="scss" scoped>
.modal__close {
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HalvarR;
  background: #000;
  color: #fff;
  border-radius: 8px;
  margin-top: 40px;
}
</style>

<style lang="scss">
.main-block {
  position: relative;
}

.gallery {
  &__block {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  &__item {
    width: 300px;
    border-radius: 8px;
    cursor: pointer;
    transition: .5s;

    &:hover {
      opacity: .5;
    }
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#000, 0.5);
    z-index: 2;

    &--window {
      background: #242424;
      padding: 30px;
      border-radius: 8px;
      text-align: center;
      font-family: HalvarR;
      color: #fff;
    }
  }
}

.image-container {
  background: transparent !important;
  width: 190px !important;
}
.image-icon-edit,
.image-icon-delete {
  fill: #fff !important;
}

span.image-primary {
  display: none !important;
}

.swiper-slide {
  display: flex !important;
  align-items: center !important;
}
</style>